import React,{useState} from 'react';
import getDate from "../../functions/getDate";
import Moment from 'react-moment';
import AACard from './AACard';
import { DatePicker } from 'react-rainbow-components';
import TimePicker from 'react-time-picker';
import putRequest from '../../functions/putRequest';
import {useStore} from '../../store.js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

const EditAACard = props => {
  const [toggleEdit, setEdit] = useState(true);
  const [eventDate, setDate] = useState(new Date(props.event.date));
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [name, setName] = useState(props.event.title);
  const [desc, setDesc] = useState(props.event.description);
  const [duration, setDuration] = useState(parseInt(props.event.meetingDuration));
  const [price, setPrice] = useState(parseInt(props.event.price));
  const [location, setLocation] = useState(props.event.location);
  const [time, setTime] = useState("18:00");
  const [time2, setTime2] = useState("19:00");
  const user = useStore(state => state.user);
  const setAAEventsFetched = useStore(state => state.setAAEventsFetched);
  const messageTimer = (message, time=5000) => {
    setTimeout(() => {
      setMessage('');
    }, time);
    setMessage(message);
  }
  const confirmSubmit = () => {
    confirmAlert({
      title: 'Confirm to update',
      message: 'Are you sure you want to update event titled: \n' + props.event.title,
      buttons: [
        {
          label: 'Yes, update.',
          onClick: () => submitEvent(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const submitEvent = () => {
    if (name.length < 4) {
      console.log("you submitted the damn event!!");
      messageTimer("The event name must be at least 4 characters long.");
      setMessageType("messageError")
      return;
    }
    if (desc.length < 15) {
      messageTimer("The event description must be at least 15 characters long.");
      setMessageType("messageError")
      return;
    }
    if (duration <= 0) {
      messageTimer("The event duration must be greater than 0.");
      setMessageType("messageError")
      return;
    }
    if (price <= 0) {
      messageTimer("The event duration must be greater than 0.");
      setMessageType("messageError")
      return;
    }
    if (location.length < 5) {
      messageTimer("The event location must be at least 5 characters.");
      setMessageType("messageError")
      return;
    }
    const start = new Date(eventDate.toString().substring(0,16) + time).toLocaleString("en-US", {timeZone: "America/New_York"});
    const end = new Date(eventDate.toString().substring(0,16) + time2).toLocaleString("en-US", {timeZone: "America/New_York"});
    const info = {
      title:name,
      description:desc,
      duration:duration.toString(),
      price:price.toString(),
      location:location,
      date:eventDate,
      startTime:start,
      endTime:end,
    }
    putRequest(`allaccess/${props.event._id}`,info, {Authorization: `Bearer ${user.token}`})
      .then(res => {
        console.log(res);
        console.log('success');
        messageTimer("Event updated!! Please refresh the page.")
        props.setMess("Success: Event Updated! Please refresh the page to view changes.");
        setMessageType('messageSuccess');
        props.setMessType('messageSuccess');
        setAAEventsFetched(false);
      })
      .catch(err => {
        console.log(err);
        console.log('fail');
        messageTimer('Request failed:' + err.message);
        setMessageType('messageError');
        props.setMess(err.response.data.error);
        props.setMessType('messageError');
      })
  }
  console.log(props.event.band);
  return (
    <div>
      {toggleEdit ?
        <div className='cardDiv'>
          <h1>Editing Mode</h1>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <p className="eventTitle">Name</p>
          <textarea className="eventInput eventDesc" value={name} onChange={e => setName(e.target.value)} placeholder="Event Name" />
          <p><strong>Description:</strong></p>
          <textarea className='eventInput eventDesc' placeholder='Event Description' value={desc} onChange={e => setDesc(e.target.value)} />
          <p className="viewEventP"><strong>You cannot edit the band associated with an event.
          Please delete event and create a new event with the proper band associated if you need to change the band.</strong></p>
          <p><strong>Meeting Duration:</strong></p>
          <input className='editInput' type="number" value={duration} onChange={e => setDuration(e.target.value)} />
          <p><strong>Price per Meeting:</strong></p>
          <input className='editInput' type="number" value={price} onChange={e => setPrice(e.target.value)} />
          <p><strong>Location:</strong></p>
          <input className='editInput' value={location} onChange={e => setLocation(e.target.value)} />
          <p><strong>Date</strong> {getDate(props.event.date)}</p>
          <DatePicker className="datepicker" value={eventDate} onChange={date => setDate(date)} />
          <p><strong>Time:</strong> From <Moment format="hh:mm a">{props.event.startTime}</Moment> to <Moment format="hh:mm a">{props.event.endTime}</Moment></p>
          <div className='outerTimeDiv'>
            <div className='innerTimeDiv'>
              <span className='spanHeader'>Start Time</span>
              <TimePicker
              onChange={setTime}
              value={time}
              className='timePicker'
              label='Start'
              />
            </div>
            <div className='innerTimeDiv'>
              <span className='spanHeader'>End Time</span>
              <TimePicker
              onChange={setTime2}
              value={time2}
              className='timePicker'
              label='End'
              />
            </div>
          </div>
          <p><strong>Current Photo:</strong> <a href={props.event.photo} target="_blank" rel="noreferrer noopener">{props.event.photo}</a></p>
          <p>Editing photos coming soon.</p>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <div className='buttonDiv'>
            <button className="submitButtonCard" onClick={confirmSubmit}>Submit Event</button>
            <button className="toggleButtonCard" onClick={() => setEdit(false)}>Back To View</button>
          </div>
        </div> : <AACard event={props.event} />
      }
    </div>
  )
}

export default EditAACard;
