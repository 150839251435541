import React, {useEffect, useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Video from './components/Video';
import Events from './components/Events';
import Users from './components/Users';
import Bands from './components/Bands';
import Adverts from './components/Adverts';
import Chat from './components/Chat';
import Images from './components/Images';
import ExportData from './components/ExportData';
import NavBar from './components/NavBar';
import {useStore} from './store.js';
import getRequest from './functions/getRequest';
import Loader from "./components/Loader";


/**
* Top Level component.
*/

const App = () => {
  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);
  const setUser = useStore(state => state.setUser);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(false);
  const messageTimer = message => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
  }
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
    if (localStorage.getItem("toke") !== null) {
      setLoading(true);
    }
  },[])
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        getRequest("auth/me", {}, {Authorization: `Bearer ${localStorage.getItem("toke")}`})
          .then(res => {
            console.log('success');
            console.log(res);
            if (!res.data.data.role === 'admin' && !res.data.data.role === 'band') {
              messageTimer("You cannot access this part.");
              setMessageType('messageError');
              return;
            }
            const userInfo = {
              name:res.data.data.name,
              email:res.data.data.email,
              username:res.data.data.username,
              avatar: res.data.data.avatar,
              authed:true,
              role:res.data.data.role,
              token:localStorage.getItem("toke"),
              band: res.data.data.role === 'band' ? res.data.data.band : null
            }
            setUser(userInfo);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false)
          })
      }, 2000)
    }
  }, [setUser,loading]);
    return (
      <Router>
        <div className="App">
          <NavBar />
          <div>
            <p className='title'>PunkHubLive Admin</p>
            <img className="logo"
              alt='Logo'
              src="https://i.ibb.co/kqKK9tC/Punk-Hub-Black-876x600.png"/>
          </div>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          {loading ? <Loader name={"Checking Token"} /> :
          <Switch>
              <Route exact path='/' render={(props) => <LandingPage />} />
              <Route exact path='/events' render={(props) => <Events />} />
              <Route exact path='/users' render={(props) => <Users />} />
              <Route exact path='/video' render={(props) => <Video />} />
              <Route exact path='/bands' render={(props) => <Bands />} />
              <Route exact path='/images' render={(props) => <Images />} />
              <Route exact path='/advertising' render={(props) => <Adverts />} />
              <Route exact path='/exportdata' render={(props) => <ExportData />} />
              <Route exact path='/chat' render={(props) => <Chat />} />
          </Switch>}
          {showButton && (
          <button onClick={scrollToTop} className="back-to-top">
            <span>Back to top</span>
          </button>
        )}
        </div>
      </Router>
    );
}

export default App;
