import React, {useState, useEffect} from 'react';
import '../App.css';
import './componentStyling/Cards.css';
import './componentStyling/Forms.css';
import {Link, Redirect} from 'react-router-dom'
import {useStore} from '../store.js';
import CreateBand from './bandComponents/CreateBand';
import EditBands from './bandComponents/EditBand';
import getRequest from '../functions/getRequest'

const Bands = () => {
  const [index, setIndex] = useState(0);
  const bandsFetched = useStore(state => state.bandsFetched);
  const setBandsFetched = useStore(state => state.setBandsFetched);
  const setBands = useStore(state => state.setBands);
  const user = useStore(state => state.user);
  useEffect(() => {
    if (!bandsFetched) {
      const getBands = async () => {
        getRequest("bands/",{}, {})
          .then(res => {
            setBandsFetched(true);
            setBands(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getBands();
    }
  },[bandsFetched, setBands, setBandsFetched]);
  return(
    <div className='eventsDiv'>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      {user.role === 'admin' || user.role === 'band' ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      <h1>Bands</h1>
      <Link to='/' className='navLink'>Back to Dashboard</Link>
      <div className='flexRow'>
        <p onClick={() => setIndex(1)} className='eventTopLink'>Create Band</p>
        <p onClick={() => setIndex(2)} className='eventTopLink'>View & Edit</p>
      </div>
      {index === 0 ? <p>Click an action above to work on an event.</p> :
      index === 1 ? <CreateBand /> :
      <EditBands />
      }
    </div>
  )
}

export default Bands;
