import React,{useState} from 'react';
import {useStore} from '../../store.js';
import ImageUpload from '../helperComponents/ImageUpload';
import ImageDisplay from '../helperComponents/ImageDisplay';
import postRequest from '../../functions/postRequest';

const CreateImage = () => {
  const [name, setName] = useState("")
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('messageSuccess');
  const user = useStore(state => state.user);
  const setImagesFetched = useStore(state => state.setImagesFetched);
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const handleSubmit = () => {
    if (name.length < 5 || name.length > 40) {
      messageTimer('The nickname must be between 5 and 20 characters', 'messageError')
    }
    if (file === null) {
      messageTimer('You must add an image.', 'messageError')
      return;
    }
    postRequest("image/",{
      name:name,
      image: file.base64.split(',')[1]
    }, {Authorization: `Bearer ${user.token}`})
    .then(async res => {
      console.log("success?")
      messageTimer('Image Created!!!', 'messageSuccess');
      resetInputs();
      setImagesFetched(false);
    })
    .catch(err => {
      messageTimer('Image not created, Reason: \n' + err.response.data.error, 'messageError');
      console.log(err.response)
    })
  }
  const resetInputs = () => {
    setName("");
    setFile(null)
  }

  return (
    <div>
      <h2>Create A New Image</h2>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      <p className="instructionText">Uploading images to the gallery helps for organizing, and reusing images as well as helping to reduce our cloud storage size.</p>
      <input className='eventInput' placeholder='Image Name' value={name} onChange={e => setName(e.target.value)}/>
      <p className="instructionText">The Image name should be between 5 and 40 characters</p>
      <ImageUpload setFile={setFile} text={'Upload The Image'} />
      <button onClick={() => setFile(null)}>Remove</button>
      {file !== null ?
        <ImageDisplay src={file.base64} text={'Ad Image Selected'} /> : null
      }      <button className='submitButton' onClick={handleSubmit}>Submit</button>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
    </div>
  )
}

export default CreateImage;
