import React, {useState} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
// import putRequest from '../../functions/deleteRequest'
// import {useStore} from '../../store.js';

const EditAds = props => {
  const [companyName, setCompanyName] = useState(props.ad.companyName);
  const [link, setLink] = useState(props.ad.linkUrl);
  // const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('messageSuccess');
  // const user = useStore(state => state.user);
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const confirmSubmit = () => {
    confirmAlert({
      title: 'Confirm to update',
      message: 'Are you sure you want to update ad titled: \n' + props.ad.companyName,
      buttons: [
        {
          label: 'Yes, update.',
          onClick: () => submitAd(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const submitAd = () => {
    console.log('sudmit')
    if (companyName === props.ad.companyName && link === props.ad.linkUrl) {
      messageTimer("You have not changed anything", "messageError")
      return
    }
    messageTimer("Editing ads is not quite functional, check back soon!!", "messageError")
  }
  return(
    <div className="cardDiv">
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      <p><strong>Ad Company Name:</strong></p>
      <input className='editInput' type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} />
      <p><strong>Ad Website:</strong></p>
      <input className='editInput' type="text" value={link} onChange={e => setLink(e.target.value)} />
      <div className='buttonDiv'>
        <button className="toggleButtonCard" onClick={confirmSubmit}>Submit Ad</button>
        <button className="deleteButtonCard" onClick={() => props.setEdit(false)}>Go Back</button>
      </div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}

    </div>
  )
}

export default EditAds
