import React, {useState, useEffect} from 'react';
import '../App.css';
import './componentStyling/Cards.css';
import './componentStyling/Forms.css';
import {Link, Redirect} from 'react-router-dom'
import CreateEvent from './CreateEvent';
import ViewEvents from './eventComponents/ViewEvents';
import {useStore} from '../store.js';
import getRequest from '../functions/getRequest';

const Events = () => {
  const [index, setIndex] = useState(0);
  const bandsFetched = useStore(state => state.bandsFetched);
  const setBandsFetched = useStore(state => state.setBandsFetched);
  const setEventsFetched = useStore(state => state.setEventsFetched);
  const eventsFetched = useStore(state => state.eventsFetched);
  const setEvents = useStore(state => state.setEvents);
  const setBands = useStore(state => state.setBands);
  const user = useStore(state => state.user);
  const setUsers = useStore(state => state.setUsers);
  const usersFetched = useStore(state => state.usersFetched);
  const setUsersFetched = useStore(state => state.setUsersFetched);
  const aAEventsFetched = useStore(state => state.aAEventsFetched);
  const setAAEventsFetched = useStore(state => state.setAAEventsFetched);
  const setAAEvents = useStore(state => state.setAAEvents);
  useEffect(() => {
    if (!aAEventsFetched) {
      const getAAEvents = async () => {
        getRequest("allaccess", {}, {})
          .then(res => {
            setAAEventsFetched(true);
            setAAEvents(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
    }
    getAAEvents();
    }
  }, [aAEventsFetched, setAAEventsFetched, setAAEvents])
  useEffect(() => {
    console.log(eventsFetched);
    if (!eventsFetched) {
      const getEvents = async () => {
        getRequest("events", {}, {})
          .then(res => {
            setEventsFetched(true);
            setEvents(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getEvents();
    }
  },[eventsFetched, setEventsFetched, setEvents]);
  useEffect(() => {
    if (!bandsFetched) {
      const getBands = async () => {
        getRequest("bands/", {}, {})
          .then(res => {
            setBandsFetched(true);
            setBands(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getBands();
    }
  },[bandsFetched, setBands, setBandsFetched]);
  useEffect(() => {
    if (!usersFetched) {
      const getUsers = async () => {
        getRequest("users", {}, {Authorization: `Bearer ${user.token}`})
          .then(res => {
            let newArr = res.data.data;
            console.log(newArr);
            setUsersFetched(true);
            setUsers(newArr);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getUsers();
    }
  }, [user.token,setUsersFetched, setUsers, usersFetched]);
  return (
    <div className='eventsDiv'>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      {user.role === 'admin' || user.role === 'band' ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      <h1>Events</h1>
      <Link to='/' className='navLink'>Back to Dashboard</Link>
      <div className='flexRow'>
        <p onClick={() => setIndex(1)} className='eventTopLink'>Create A New Event</p>
        <p onClick={() => setIndex(2)} className='eventTopLink'>View And Edit Events</p>
      </div>
      {index === 0 ? <p>Click an action above to work on an event.</p> :
      index === 1 ? <CreateEvent /> :
      <ViewEvents />
      }
    </div>
  )
}
export default Events;
