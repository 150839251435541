import React, {useState} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import deleteRequest from '../../functions/deleteRequest'
import {useStore} from '../../store.js';
import EditAds from "./EditAds"

const AdCard = props => {
  const user = useStore(state => state.user);
  const setAdsFetched = useStore(state => state.setAdsFetched);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [edit, setEdit] = useState(false);
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete ad titled: \n' + props.ad.companyName,
      buttons: [
        {
          label: 'Yes, delete.',
          onClick: () => deleteAd(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const deleteAd = () => {
    deleteRequest(`ads/${props.ad._id}`,{},{Authorization: `Bearer ${user.token}`})
      .then(res => {
        setMessage('Successfully deleted the event.');
        setMessageType('messageSuccess');
        messageTimer("Successfully deleted the event.", "messageSuccess");
        setAdsFetched(false);
        console.log("success")
      })
      .catch(err => {
        setMessage('Event was not deleted.');
        setMessageType('error');
        messageTimer("Event was not deleted.", "messageError");
        console.log("err")
      })

  }

  return (
    <div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      {!edit ?
        <div className="cardDiv">
          <p className="eventTitle">{props.ad.companyName}</p>
          <p><strong>Ad Nick Name: </strong>{props.ad.nickName}</p>
          <p><strong>Company Website: </strong><a href={props.ad.linkUrl}>{props.ad.linkUrl}</a></p>
          {props.ad.type === 'image' ?
            <img className='image' alt='Event' src={props.ad.imageUrl} /> :
            <video width="320" height="240" controls>
              <source src={props.ad.imageUrl} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
          }
          <p><strong>Image Storage Path: </strong>{props.ad.imageUrl}</p>
          <div className='buttonDiv'>
            <button className="toggleButtonCard" onClick={() => setEdit(true)}>Edit Ad</button>
            <button className="deleteButtonCard" onClick={confirmDelete}>Delete Ad</button>
          </div>
        </div> :
        <EditAds ad={props.ad} setEdit={setEdit} />
      }
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
    </div>
  )
}

export default AdCard;
