import React, {useState} from 'react';
import getDate from "../../functions/getDate";
import Moment from 'react-moment';
import EditAACard from './EditAACard';
import {useStore} from '../../store.js';
import deleteRequest from '../../functions/deleteRequest'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import BookingCard from "./BookingCard";

const AACard = props => {
  const [toggleEdit, setEdit] = useState(false);
  const bands = useStore(state => state.bands);
  const band = bands.find(x => x._id === props.event.band);
  const user = useStore(state => state.user);
  const setAAEventsFetched = useStore(state => state.setEventsFetched);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showBookings, setShowBookings] = useState(false);
  props.event.booking = props.event.booking.sort(function(a,b){return new Date(a.slotStartTime) - new Date(b.slotStartTime)});
  // console.log(b)
  // const eventType = props.event.type === 'mkr' ? "MKR" : "Band"
  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete event titled: ' + props.event.title,
      buttons: [
        {
          label: 'Yes, delete.',
          onClick: () => deleteEvent(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const deleteEvent = () => {
    console.log("you deleted the damn event!!");
    deleteRequest(`allaccess/${props.event._id}`,{},{Authorization: `Bearer ${user.token}`})
      .then(res => {
        console.log(res);
        console.log('success');
        setMessage("Successfully deleted the event.");
        setMessageType('messageSuccess');
        setAAEventsFetched(false);
      })
      .catch(err => {
        console.log(err);
        console.log('fail');
        setMessage("Did not delete event.");
        setMessageType('messageError');
      })
  }
  const updateEvents = () => {

  }
  return (
    <div>
      {toggleEdit ? <EditAACard setMessType={props.setMessType} setMess={props.setMess} event={props.event} /> :
        <div className='cardDiv'>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <p className="eventTitle">{props.event.title}</p>
          <p>{props.event.id}</p>
          <p><strong>Description:</strong> {props.event.description}</p>
          {band === undefined ? null : <p><strong>Band:</strong>{band.name}</p>}
          <p><strong>Meeting Duration:</strong>{props.event.meetingDuration}</p>
          <p><strong>Meeting Price:</strong>${props.event.price}/session</p>
          <p><strong>Location:</strong> {props.event.location}</p>
          <p><strong>Date</strong> {getDate(props.event.date)}</p>
          <p><strong>Time:</strong> From <Moment format="hh:mm a">{props.event.startTime}</Moment> to <Moment format="hh:mm a">{props.event.endTime}</Moment></p>
          <p><strong>Photo:</strong></p> <img className='image' alt='Event' src={props.event.photo} />
          <p><strong>Created:</strong> <Moment>{props.event.createDate}</Moment></p>
          <h2><u>Bookings</u></h2>
          {!showBookings ?
            <button className="toggleButtonCard" onClick={() => setShowBookings(true)}>View Bookings</button> :
            <div>
              <button className="toggleButtonCard" onClick={() => setShowBookings(false)}>Hide Bookings</button>
                {
                  props.event.booking.map((b,i) => <div className="bookingOuterDiv" key={i}>{<BookingCard triggerCall={updateEvents} id={props.event.id} eventTitle={props.event.title} booking={b} i={i}/>}</div>)
                }
              <button className="toggleButtonCard" onClick={() => setShowBookings(false)}>Hide Bookings</button>
            </div>

          }
          <div className='buttonDiv'>
            <button className="toggleButtonCard" onClick={() => setEdit(true)}>Edit Event</button>
            <button className="deleteButtonCard" onClick={confirmDelete}>Delete Event</button>
          </div>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
        </div>}
    </div>
  )
}

export default AACard;
