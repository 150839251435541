import React,{useState} from 'react';
import getDate from "../../functions/getDate";
import Moment from 'react-moment';
import EventCard from './EventCard';
import EditDateComp from './EditDateComp'
import { DatePicker } from 'react-rainbow-components';
import TimePicker from 'react-time-picker';
import putRequest from "../../functions/putRequest";
import {useStore} from '../../store.js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import validator from 'validator'
import DateTime from '../helperComponents/DateTime.js';

const EditEventCard = props => {
  const user = useStore(state => state.user);
  const [toggleEdit, setEdit] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [eventDate, setDate] = useState(new Date(props.event.date));
  const [addDate, toggleAddDate] = useState(false)
  const d = props.event.dates !== undefined ? props.event.dates.sort((a,b) => a.date - b.date) : {}
  const [dates, setDates] = useState(d)
  const [name, setName] = useState(props.event.name);
  const [region, setRegion] = useState(props.event.eventLocation);
  const [desc, setDesc] = useState(props.event.description);
  const [link, setLink] = useState(props.event.link);
  const [venue, setVenue] = useState(props.event.venue);
  const [location, setLocation] = useState(props.event.location);
  const [time, setTime] = useState("18:00");
  const [time2, setTime2] = useState("19:00");
  const [dLink, setDLink] = useState("");
  const [dVenue, setDVenue] = useState("");
  const [dLocation, setDLocation] = useState("");
  const [dTime, setDTime] = useState("18:00");
  const [dTime2, setDTime2] = useState("19:00");
  const [dDate, setDDate] = useState(new Date())
  const setEventsFetched = useStore(state => state.setEventsFetched);

  const messageTimer = (message, type, time=5000) => {
    console.log(dates)
    setTimeout(() => {
      setMessage('');
    }, time);
    setMessage(message);
    setMessageType(type)
  }

  const confirmSubmit = () => {
    confirmAlert({
      title: 'Confirm to update',
      message: 'Are you sure you want to update event titled: \n' + props.event.name,
      buttons: [
        {
          label: 'Yes, update.',
          onClick: () => submitEvent(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const submitEvent = () => {
    let info;
    if (name.length < 4) {
      messageTimer("The event name must be at least 4 characters long.", "messageError");
      return;
    }
    if (desc.length < 15) {
      messageTimer("The event description must be at least 15 characters long.", "messageError");
      return;
    }
    if (props.event.dates === undefined) {
      if (link.length < 6) {
        messageTimer("The event link must be at least 6 characters long.", "messageError");
        return;
      }
      if (venue.length < 5) {
        messageTimer("The event venue must be at least 5 characters long.", "messageError");
        return;
      }
      if (location.length < 5) {
        messageTimer("The event location must be at least 5 characters long.", "messageError");
        return;
      }
      info = {
        name:name,
        description:desc,
        link:link,
        venue:venue,
        location:location,
        dates:dates.sort((a,b) => new Date(a.date) - new Date(b.date)),
        startDate:dates[0].date,
        date:dates[dates.length-1].date,
        eventLocation:region,
      }
      update(info);
    } else {
      dates.forEach((d,i) => {
        const start = new Date(d.startTime);
        const end = new Date(d.endTime);
        if (end <= start) {
          messageTimer("The start time must be before the end time.", 'messageError');
          return;
        }
        if (!validator.isURL(d.link)) {
          messageTimer("The event link must be at least 6 characters long.", "messageError");
          return;
        }
        if (d.venue.length < 5) {
          messageTimer("The event venue must be at least 5 characters long.", "messageError");
          return;
        }
        if (d.location.length < 5) {
          messageTimer("The event location must be at least 5 characters long.", "messageError");
          return;
        }
        console.log(d);
      })
      info = {
        name:name,
        description:desc,
        link:link,
        venue:venue,
        location:location,
        dates:dates.sort((a,b) => new Date(a.date) - new Date(b.date)),
        startDate:dates[0].date,
        date:dates[dates.length-1].date,
        eventLocation:region,
      }
      console.log(dates)
      update(info);
    }
  }
  const update = info => {
    putRequest(`events/${props.event._id}`,info, {Authorization: `Bearer ${user.token}`})
      .then(res => {
        console.log(res);
        console.log('success');
        setEventsFetched(false);
        messageTimer("Event updated!!", 'messageSuccess')
      })
      .catch(err => {
        console.log(err);
        console.log('fail');
        messageTimer('Request failed:' + err.response.data.error, "messageErrpr");
      })

  }
  const updateLocation = (index, value) => {
    console.log(dates[0].location)
    let copy = dates;
    copy[index].location = value;
    setDates(copy);
  }
  const updateVenue = (index, value) => {
    console.log(dates[0].venue)
    let copy = dates;
    copy[index].venue = value;
    setDates(copy);
  }
  const updateLink = (index, value) => {
    let copy = dates;
    copy[index].link = value;
    setDates(copy);
  }
  const updateDate = (index, value, time, time2) => {
    const start = new Date(value.toString().substring(0,16) + time);
    const end = new Date(value.toString().substring(0,16) + time2);
    let copy = dates;
    copy[index].date = value;
    copy[index].startTime = start;
    copy[index].endTime = end;
    setDates(copy);
  }
  const updateStart = (index, time) => {
    const start = new Date(dates[index].date.toString().substring(0,16) + time);
    let copy = dates;
    copy[index].startTime = start;
    setDates(copy)
  }
  const updateEnd = (index, time) => {
    const end = new Date(dates[index].date.toString().substring(0,16) + time);
    let copy = dates;
    copy[index].endTime = end;
    setDates(copy)
  }
  const removeDate = id => {
    setDates(dates.filter(d => d._id !== id))
  }
  const addADate = () => {
    let linkUrl;
    if (dLink.length > 0 && !validator.isURL(dLink)) {
      messageTimer("Please add a proper url.", "messageError")
      return
    } else {
      linkUrl = dLink === '' ? 'https://manickatrecords.com' : dLink
    }
    console.log(linkUrl)
    if(dLocation.length > 0 && dVenue.length > 0) {
      const start = new Date(dDate.toString().substring(0,16) + dTime);
      const end = new Date(dDate.toString().substring(0,16) + dTime2);
      if (end <= start) {
        messageTimer("The start time must be before the end time.", 'messageError');
        return;
      }
      let copyArr = dates;
      copyArr.push({
        // id:Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        location:dLocation,
        venue:dVenue,
        link:linkUrl,
        date:dDate,
        startTime:start,
        endTime:end,
      });
      setDates(copyArr.sort((a,b)  => new Date(a.date) - new Date(b.date)));
      clearDateInfo()
      toggleAddDate(false)
    } else {
      messageTimer("Please add data for all required inputs.", "messageError")
      return
    }
  }
  const clearDateInfo = () => {
    setDLocation("");
    setDLink("");
    setDVenue("");
    setDDate(new Date());
  }
  console.log(dates)
  // const eventType = props.event.type === 'mkr' ? "MKR" : "Band
  return (
    <div>
      {toggleEdit ?
        <div className='cardDiv'>
          <h1>Editing Mode</h1>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <p className='formSectHeader'><strong>Event Name:</strong></p>
          <input className="eventInput" value={name} onChange={e => setName(e.target.value)} placeholder="Event Name" />
          <p className='formSectHeader'><strong>Description:</strong></p>
          <textarea className='eventInput eventDesc' placeholder='Event Description' value={desc} onChange={e => setDesc(e.target.value)} />
          <p className='formSectHeader'><strong>Event Region:</strong></p>
          <input className="eventInput" value={region} onChange={e => setRegion(e.target.value)} placeholder="Event Region" />
          <p className="viewEventP"><strong>You cannot edit the band associated with an event.
          Please delete event and create a new event with the proper band associated if you need to change the band.</strong></p>
          {props.event.dates === undefined ?
            <div>
              <p className='formSectHeader'><strong>Location:</strong></p>
              <input className='editInput' value={location} onChange={e => setLocation(e.target.value)} />
              <p className='formSectHeader'><strong>Venue:</strong></p>
              <input className='editInput' value={venue} onChange={e => setVenue(e.target.value)} />
              <p className='formSectHeader'><strong>Link:</strong></p>
              <input className='editInput' value={link} onChange={e => setLink(e.target.value)} />
              <input className='editInput' value={region} onChange={e => setRegion(e.target.value)} />
              <p className='formSectHeader'><strong>Date</strong> {getDate(props.event.date)}</p>
              <DatePicker className="datepicker" value={eventDate} onChange={date => setDate(date)} />
              <p className='formSectHeader'><strong>Time:</strong> From <Moment format="hh:mm a">{props.event.startTime}</Moment> to <Moment format="hh:mm a">{props.event.endTime}</Moment></p>
              <div className='outerTimeDiv'>
                <div className='innerTimeDiv'>
                  <span className='spanHeader'>Start Time</span>
                  <TimePicker
                  onChange={setDTime}
                  value={time}
                  className='timePicker'
                  label='Start'
                  />
                </div>
                <div className='innerTimeDiv'>
                  <span className='spanHeader'>End Time</span>
                  <TimePicker
                  onChange={setDTime2}
                  value={time2}
                  className='timePicker'
                  label='End'
                  />
                </div>
              </div>
            </div> :
            <div>
              <div className='datesContainer'>
              {dates.map((d,i) => (
                  <EditDateComp key={d.id} date={d} index={i} updateLocation={updateLocation} updateLink={updateLink} updateVenue={updateVenue}
                  updateDate={updateDate} removeDate={removeDate} updateStart={updateStart} updateEnd={updateEnd} />
              ))}
              </div>
              {addDate ?
                <div className='datesDiv'>
                  <p className="eventTitle">Adding a Date</p>
                  <input className='eventInput' placeholder='Location' value={dLocation} onChange={e => setDLocation(e.target.value)}/>
                  <input className='eventInput' placeholder='Venue' value={dVenue} onChange={e => setDVenue(e.target.value)}/>
                  <p>Set the tour date link below, if it is left empty the event link will default to https://manickatrecords.com</p>
                  <input className='eventInput' placeholder='Event Link' value={dLink} onChange={e => setDLink(e.target.value)}/>
                  <DateTime time={dTime} time2={dTime2} date={dDate} setDate={setDDate} setTime={setTime} setTime2={setTime2}/>
                  {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
                  <button className='invToggleButtonCard' onClick={addADate}>Add Date Info</button>
                </div> : null
              }
              <div className='buttonDiv'>
                <button className='submitButtonCard' onClick={() => toggleAddDate(!addDate)}>{addDate ? "Minimize" : "Add a Date"}</button>
                <button className="toggleButtonCard" onClick={() => setDates(props.event.dates)}>Reset all Dates</button>
              </div>
            </div>
          }
          <p><strong>Current Photo:</strong> <a href={props.event.photo} target="_blank" rel="noreferrer noopener">{props.event.photo}</a></p>
          <p>Editing photos coming soon.</p>
          <div className='buttonDiv'>
            <button className="submitButtonCard" onClick={confirmSubmit}>Submit Event</button>
            <button className="toggleButtonCard" onClick={() => setEdit(false)}>Back To View</button>
          </div>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
        </div> : <EventCard event={props.event} />
      }
    </div>
  )
}

export default EditEventCard;
