import React, {useState} from 'react';
import Select from 'react-select'
import {useStore} from '../store.js';
import CreateAAEvent from './allAccessComponents/CreateAAEvent';
import CreateRegularEvent from './eventComponents/CreateRegularEvent';

const CreateEvent = () => {
  let bandList;
  // App state variables
  const bands = useStore(state => state.bands);
  // Options for all the selectors
  const options = [
    {value:'event', label:' Regular Event'},
    {value:'allAccess', label:'All Access Event'}
  ];
  // Input variables
  const [eventType, setEvent] = useState('');

  bandList = bands.map((band, i)=> {return {value: i, label: band.name}});

  return (
    <div>
      <h2>Create Event</h2>
      <div className='eventForm'>
        <Select className='select' options={options} placeholder='Event Type' onChange={selection => setEvent(selection.value)} />
        {eventType === '' ?
          <p>Select the event type in order to proceed.</p> : eventType === 'allAccess' ?
          <CreateAAEvent bandList={bandList} /> : <CreateRegularEvent bandList={bandList} /> }
      </div>
    </div>
  )
}

export default CreateEvent;
