import create from 'zustand';


export const useStore = create(set => ({
  // Current User Data
  user: {
    name: '',
    username: '',
    email:'',
    authed: false,
    role:'',
    token:'',
    avatar: '',
    band:''
  },
  setUser: info => set(state => ({user:info})),
// Users data
  usersFetched: false,
  setUsersFetched: bool => set(state => ({usersFetched:bool})),
  users: [],
  setUsers: list => set(state => ({ users: list })),
// Events Data
  eventsFetched: false,
  setEventsFetched: bool => set(state => ({eventsFetched:bool})),
  events:[],
  setEvents: list => set(state => ({ events: list })),
// All Access Event Data
  aAEventsFetched: false,
  setAAEventsFetched: bool => set(state => ({aAEventsFetched:bool})),
  aAEvents:[],
  setAAEvents: list => set(state => ({aAEvents: list })),
//  Band Data
  bandsFetched: false,
  setBandsFetched: bool => set(state => ({bandsFetched:bool})),
  bands: [],
  setBands: list => set(state => ({bands: list })),
// Ads Data
  adsFetched: false,
  setAdsFetched: bool => set(state => ({adsFetched:bool})),
  ads: [],
  setAds: list => set(state => ({ads: list})),
  // Image data
  imagesFetched: false,
  setImagesFetched: bool => set(state => ({imagesFetched:bool})),
  images: [],
  setImages: list => set(state => ({images: list })),
  // Chat data
  chatsFetched: false,
  setChatsFetched: bool => set(state => ({chatsFetched:bool})),
  chats: [],
  setChats: list => set(state => ({chats: list })),
  addMessage: message => set(state => ({chats: [...state.chats, message]}))
}))
