import React, {useState, useEffect} from 'react';
import '../App.css';
import './componentStyling/Cards.css';
import './componentStyling/Forms.css';
import {Link, Redirect} from 'react-router-dom'
import {useStore} from '../store.js';
import CreateUser from './userComponents/CreateUser';
import GetUser from './userComponents/GetUser';
import getRequest from '../functions/getRequest';

const Bands = () => {
  const [index, setIndex] = useState(0);
  const bandsFetched = useStore(state => state.bandsFetched);
  const setBandsFetched = useStore(state => state.setBandsFetched);
  const setBands = useStore(state => state.setBands);
  const user = useStore(state => state.user);
  const users = useStore(state => state.users);
  const setUsers = useStore(state => state.setUsers);
  const usersFetched = useStore(state => state.usersFetched);
  const setUsersFetched = useStore(state => state.setUsersFetched)
  useEffect(() => {
    if (!bandsFetched) {
      const getBands = async () => {
        getRequest("bands/", {}, {})
          .then(res => {
            setBandsFetched(true);
            setBands(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getBands();
    }
  },[bandsFetched, setBands, setBandsFetched]);

  useEffect(() => {
    if (!usersFetched) {
      const getUsers = async () => {
        getRequest("users", {}, {Authorization: `Bearer ${user.token}`})
          .then(res => {
            setUsersFetched(true);
            setUsers(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
    }
    getUsers();
    }
  }, [usersFetched, setUsersFetched, setUsers, user.token]);
  console.log(users);
  return(
    <div className='eventsDiv'>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      {user.role === 'admin' || user.role === 'band' ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      <h1>Events</h1>
      <Link to='/' className='navLink'>Back to Dashboard</Link>
      <div className='flexRow'>
        <p onClick={() => setIndex(1)} className='eventTopLink'>Create User</p>
        <p onClick={() => setIndex(2)} className='eventTopLink'>View Users</p>
      </div>
      {index === 0 ?<p>Select an action above.</p> :
      index === 1 ?  <CreateUser /> :
      <GetUser users={users} />
      }
    </div>
  )
}

export default Bands;
