import React,{useState} from 'react';
import Moment from 'react-moment';
import EditEventCard from './EditEventCard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import deleteRequest from '../../functions/deleteRequest'
import {useStore} from '../../store.js';

const EventCard = props => {
  const [toggleEdit, setEdit] = useState(false);
  const user = useStore(state => state.user);
  const setEventsFetched = useStore(state => state.setEventsFetched);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [toggleDates, setToggleDates] = useState(false);
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type);
  }
  // useEffect(() => {
  //
  // }, [events])
  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete event titled: \n' + props.event.name,
      buttons: [
        {
          label: 'Yes, delete.',
          onClick: () => deleteEvent(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const deleteEvent = () => {
    deleteRequest(`events/${props.event._id}`,{},{Authorization: `Bearer ${user.token}`})
      .then(res => {
        setEventsFetched(false);
        messageTimer("Successfully deleted the event.", "messageSuccess");
      })
      .catch(err => {
        messageTimer(err.message, "messageError");
      })

  }
  return (
    <div>
      {toggleEdit ? <EditEventCard setMessType={props.setMessType} setMess={props.setMess} event={props.event} /> :
        <div className='cardDiv'>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <p className="eventTitle">{props.event.name}</p>
          <p><strong>Description:</strong> {props.event.description}</p>
          <p><strong>Region:</strong> {props.event.eventLocation}</p>
          <p><strong>Stream Type:</strong> {props.event.streamType.toUpperCase()}</p>
          {!toggleDates ?
            <div>
              <button className="toggleButtonCard" onClick={() => setToggleDates(true)}>Show Dates</button>
            </div> :
            <div>
              <button className="toggleButtonCard" onClick={() => setToggleDates(false)}>Hide Dates</button>
              <div className="datesContainer">
              {props.event.dates.map((d,i) => (
                <div className="datesDiv" key={i}>
                  <p>Date #{i+1}</p><p> <strong>Location:</strong> {d.location} <strong>Venue:</strong> {d.venue}</p>
                  <p style={{wordBreak:"break-all"}}><strong>Link:</strong> {d.link}</p>
                  <p><Moment format="LL">{d.date}</Moment></p>
                  <p><Moment format="h:mmA">{d.startTime}</Moment>-<Moment format="h:mmA">{d.endTime}</Moment></p>
                </div>
                ))}
              </div>
              <button className="toggleButtonCard" onClick={() => setToggleDates(false)}>Hide Dates</button>
            </div>
          }
          <img className='image' alt='Event' src={props.event.photo} />
          <p><strong>Created:</strong> <Moment trim>{props.event.createDate}</Moment></p>
          <div className='buttonDiv'>
            <button className="toggleButtonCard" onClick={() => setEdit(true)}>Edit Event</button>
            <button className="deleteButtonCard" onClick={confirmDelete}>Delete Event</button>
          </div>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
        </div>}
    </div>
  )
}

export default EventCard;
