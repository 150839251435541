import React, {useState} from 'react';
import {useStore} from '../../store.js';
import postRequest from '../../functions/postRequest';
import Select from 'react-select';

const CreateUser = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const bands = useStore(state => state.bands);
  const user = useStore(state => state.user);
  const setUsersFetched = useStore(state => state.setUsersFetched);
  const [userType, selectType] = useState('');
  const [bandIndex,setBand] = useState(-1);
  const options = [
    {value:'user', label:'User'},
    {value:'admin', label:'Admin'},
    {value:'band', label:'Band'}
  ];
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('messageSuccess');
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  let bandList;
  if (userType === 'band') {
    bandList = bands.map((band, i)=> {return {value: i, label: band.name}});
  }
  const handleSubmit = () => {
    const info = {
      name: name,
      email: email,
      password:password,
      role:userType,
      acceptTOS: true,
      getNews: true,
      band: userType === 'band' ? bands[bandIndex]._id : null,
    }
    console.log(info);
    if (name.length < 2) {
      messageTimer("User's name must be at least 2 characters.", 'messageError');
      return;
    }
    let re = /\S+@\S+\.\S+/;
    if (email.length === 0) {
      messageTimer("You must add an email.", 'messageError');
      return;
    }
    if(!re.test(email)) {
      messageTimer("Email must be in email format.", 'messageError');
      return;
    }
    if (password.length < 6) {
      messageTimer("User's password must be at least 6 characters.", 'messageError');
      return;
    }
    if (password !== confirm) {
      messageTimer("Passwords do not match.", 'messageError');
      return;
    }
    if (userType !== 'band' && userType !== 'user' && userType !== 'admin') {
      messageTimer("Please select a user type.", 'messageError');
      return;
    }
    postRequest('auth/register', info, {Authorization: `Bearer ${user.token}`})
      .then(res => {
        messageTimer("User Created!!.", 'messageSuccess');
        setUsersFetched(false);
      })
      .catch(err => {
        messageTimer("Could not create user, try again. REASON:\n" + err.response.data.error, 'messageError');
      })
  }
  return (
    <div>
      <h2>Create A User</h2>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
      : null}
      <input className='eventInput' placeholder='Username' value={name} onChange={e => setName(e.target.value)} />
      <input className='eventInput' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)}/>
      <input type='password' className='eventInput' placeholder='Password' value={password} onChange={e => setPassword(e.target.value)}/>
      <input type='password' className='eventInput' placeholder='Confirm Password' value={confirm} onChange={e => setConfirm(e.target.value)}/>
      <Select className='select' options={options} placeholder='User Type' onChange={selection => selectType(selection.value)} />
      {userType === 'band' ? <div>
      <Select className='select' options={bandList} placeholder='Select The Band' onChange={selection => setBand(selection.value)} />
      </div> : null}
      <button className='submitButton' onClick={handleSubmit}>Submit</button>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
      : null}
    </div>
  )
}

export default CreateUser;
