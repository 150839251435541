import React, {useState} from 'react';
import postRequest from '../../functions/postRequest';
import putRequest from '../../functions/putRequest';
import ImageUpload from '../helperComponents/ImageUpload';
import {useStore} from '../../store.js';

const CreateBand = () => {
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [spotify, setSpotify] = useState('')
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [website, setWebsite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [groupPhoto, setGroupPhoto] = useState(null);
  const [titlePhoto, setTitlePhoto] = useState(null);
  const [message, setMessage] = useState('');
  const setBandsFetched = useStore(state => state.setBandsFetched);
  const [messageType, setMessageType] = useState('');
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type);
  }
  const handleSubmit = () => {
    if (name.length <= 2 || desc <= 14) {
      messageTimer("name must be at least be at least 3 characters and description must be at least 15", 'messageError')
      return;
    }
    if (groupPhoto === null || titlePhoto === null) {
      messageTimer("Please set both the group and title photo", 'messageError')
      return;
    }
    const info = {
      name: name,
      description: desc,
      website:website,
      facebook:facebook,
      twitter:twitter,
      instagram:instagram,
      spotifyTrack: spotify,
    }
    const photos = {
      titlePhoto: titlePhoto.base64.split(',')[1],
      groupPhoto: groupPhoto.base64.split(',')[1]
    }
    postRequest("bands", info, {Authorization: `Bearer ${localStorage.getItem("toke")}`})
      .then(res => {
        messageTimer("Successfully created Band.", "messageSuccess");
        return putRequest(`bands/${res.data.band.id}/photo`,photos, {Authorization: `Bearer ${localStorage.getItem("toke")}`})
      })
      .then(res => {
        console.log('success');
        console.log(res);
        messageTimer("Band created and images saved to cloudds!", "messageSuccess");
        setBandsFetched(false)
      })
      .catch(err => {
        messageTimer("Did not create band, sorry!" + err.message, "messageError");
      })
  }
  return (
    <div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      <input className='eventInput' placeholder='Band Name' value={name} onChange={e => setName(e.target.value)}/>
      <textarea className='eventInput eventDesc' placeholder='Band Description' value={desc} onChange={e => setDesc(e.target.value)} />
      <p>Band Spotify track is not required. Only put the track id here, not the full embed code.</p>
      <input className='eventInput' placeholder='Spotify Track ID' value={spotify} onChange={e => setSpotify(e.target.value)}/>
      <p>Band Spotify track, Website, Facebook, Instagram, and Twitter are not required.</p>
      <input className='eventInput' placeholder='Band Website' value={website} onChange={e => setWebsite(e.target.value)}/>
      <input className='eventInput' placeholder='Band Facebook' value={facebook} onChange={e => setFacebook(e.target.value)}/>
      <input className='eventInput' placeholder='Band Twitter' value={twitter} onChange={e => setTwitter(e.target.value)}/>
      <input className='eventInput' placeholder='Band Instagram' value={instagram} onChange={e => setInstagram(e.target.value)}/>
      <div className='uploadDiv'>
        <ImageUpload setFile={setGroupPhoto} text='Group Photo'/>
        <p style={{marginTop:0}}>This is the color photo with no text</p>
        <ImageUpload setFile={setTitlePhoto} text='Title Photo'/>
        <p style={{marginTop:0}}>This is the black and white photo with artist title.</p>
      </div>
      <button className='submitButton' onClick={handleSubmit}>Submit</button>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
    </div>
  )
}

export default CreateBand;
