import React, {useState, useEffect} from 'react';

const Timer = props => {
  const [seconds, setSeconds] = useState(((new Date(props.end).getTime()/1000) - (new Date().getTime()/1000)).toFixed(0));
  // const [intervalId, setIntervalID] = useState(null);
  if (seconds <= 120 && props.eventStartTimer) {
    props.updateEventInProgress();
  }
  useEffect(() => {
    let timer
    if (seconds > 0) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0)
      return clearTimeout(timer);
    }
  }, [seconds])
  let days = Math.floor(seconds/86400);
  let hours = Math.floor(seconds%(3600*24)/3600)
  let minutes = Math.floor(seconds%3600/60);
  let secs = Math.floor(seconds%60);
  let day = days === 1 ? ' ' + days + ' day, '
    : days === 0 ? ''
    : days + ' days, ';
  let hour = hours === 1 ? hours + ' hour, '
    : hours + ' hours, '
  let minute = minutes === 1 ? minutes + ' minute, '
    : minutes + ' minutes, ';
  let sec = secs === 1 ? secs + ' second.'
    : secs + ' seconds.';
  return(
    <div className={seconds < 120 && seconds > 0 ? 'timerDiv timerDivWarn' : seconds <= 0 ? 'timerDiv timerDivOver' : 'timerDiv'}>
      <p align='center'><strong>{seconds <= 0 ? 'This time slot is done!!' : props.text}</strong> {day}{hour}{minute}{sec}</p>
    </div>
  )
}



export default Timer;
