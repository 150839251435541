import React, {useState} from 'react';
import {useStore} from '../../store.js';
import AACard from "./AACard";

const ViewAllAccessComp = () => {
  const aAEvents = useStore(state => state.aAEvents);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [filter, setFilter] = useState("");
  return (
    <div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
      : null}
      <p style={{fontSize:"1.25rem"}}>Event Filter</p>
      <p style={{fontSize:"1.5rem"}}><strong>{filter.length > 0 ? filter : "No Filter"}</strong></p>
      <input className='eventInput' value={filter} placeholder='Filter Events' onChange={e => setFilter(e.target.value)}/>
      {aAEvents.length > 0  ? aAEvents.map((aa, i) =>
        aa.title.toLowerCase().includes(filter.toLowerCase()) ?
          <AACard setMessType={setMessageType} setMess={setMessage} key={i} event={aa} /> : null)
        : null}
    </div>
  )
}

export default ViewAllAccessComp;
