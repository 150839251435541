import React, { useState } from 'react';
import BandCard from './BandCard';
import {useStore} from '../../store.js';

const EditBand = () => {
  const bands = useStore(state => state.bands);
  const [band, setBand] = useState('');
  bands.sort(function(a, b) {
      var textA = a.name.toLowerCase();
      var textB = b.name.toLowerCase();
      if (textA.slice(0,3) === "the") {
        textA = textA.slice(4);
      }
      if (textB.slice(0,3) === "the") {
        textB = textB.slice(4);
      }
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  return (
    <div>
      {bands.length === 0 ? <p>No bands loaded.</p> :
        <div>
          <p style={{fontSize:"1.25rem"}}>Band Filter</p>
          <p style={{fontSize:"1.5rem"}}><strong>{band.length > 0 ? band : "No Filter"}</strong></p>
          <input className='eventInput' value={band} placeholder='Filter Bands' onChange={e => setBand(e.target.value)}/>
          {bands.map((b, i) =>
            <div key={b._id}>
              {b.name.toLowerCase().includes(band.toLowerCase()) ?
                <BandCard key={b._id} band={b} /> : null
              }
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default EditBand;
