import React,{useState} from 'react';
import BandCard from './BandCard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import putRequest from '../../functions/putRequest';
import ImageUpload from '../helperComponents/ImageUpload';
import {useStore} from '../../store.js';

const EditBandCard = props => {
  const [edit, setEdit] = useState(true);
  const [name, setName] = useState(props.band.name);
  const [desc, setDesc] = useState(props.band.description);
  const [spotify, setSpotify] = useState(props.band.spotifyTrack);
  const [website, setWebsite] = useState(props.band.website);
  const [facebook, setFacebook] = useState(props.band.facebook);
  const [twitter, setTwitter] = useState(props.band.twitter);
  const [instagram, setInstagram] = useState(props.band.instagram);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [editGroupPhoto, setEditGroup] = useState(false);
  const [editTitlePhoto, setEditTitle] = useState(false);
  const [groupPhoto, setGroupPhoto] = useState(null);
  const [titlePhoto, setTitlePhoto] = useState(null);
  const setBandsFetched = useStore(state => state.setBandsFetched);
  const messageTimer = (message, time=5000) => {
    setTimeout(() => {
      setMessage('');
    }, time);
    setMessage(message);
  }
  const confirmSubmit = () => {
    confirmAlert({
      title: 'Confirm to update',
      message: 'Are you sure you want to update band titled: \n' + props.band.name,
      buttons: [
        {
          label: 'Yes, update.',
          onClick: () => submitBand(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }

  const submitBand = () => {
    const info = {
      name: name,
      description: desc,
      website:website,
      facebook:facebook,
      twitter:twitter,
      instagram:instagram,
      spotifyTrack:spotify,
    }
    let photos;
    console.log(typeof photos);
    if (editGroupPhoto && editTitlePhoto && groupPhoto !== null && titlePhoto !== null) {
      photos = {
        groupPhoto: groupPhoto.base64.split(',')[1],
        titlePhoto: titlePhoto.base64.split(',')[1]
      }
    }else if(editGroupPhoto && groupPhoto !== null) {
      photos = {
        groupPhoto: groupPhoto.base64.split(',')[1]
      }
    }else if(editTitlePhoto && titlePhoto !== null) {
      photos = {
        titlePhoto: titlePhoto.base64.split(',')[1],
      }
    }
    console.log(info);
    putRequest(`bands/${props.band._id}`,info, {Authorization: `Bearer ${localStorage.getItem('toke')}`})
      .then(res => {
        setMessageType('messageSuccess');
        if (photos !== undefined) {
          updatePhoto(photos);
        } else {
          setBandsFetched(false);
          setTimeout(() => {
            setEdit(false);
          }, 3000)
        }
        messageTimer('Updated Band!!');

        console.log(info);
      })
      .catch(err => {
        setMessageType('messageError');
        messageTimer('Could not update band, sorry');
      })
  }
  const updatePhoto = photos => {
    putRequest(`bands/${props.band.id}/photo`,photos, {Authorization: `Bearer ${localStorage.getItem("toke")}`})
      .then(res => {
        console.log(res);
        messageTimer("Group Photo updated", "message")
        setTimeout(() => {
          setEdit(false);
        }, 3000)
      })
  }
  return(
    <div>
      {edit ?
        <div className='cardDiv'>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <p className="cardHeader">Band Name:</p>
          <input className="eventInput" value={name} onChange={e => setName(e.target.value)} />
          <p className="cardHeader">Description:</p>
          <textarea className="eventInput bandDescInput" value={desc} onChange={e => setDesc(e.target.value)} />
          <p className='cardHeader'>Group Photo:</p>
          {!editGroupPhoto ?
            <div style={{marginBottom:'10px'}}>
              <img className='image' alt='Event' src={props.band.groupPhoto} />
              <div>
                <button className='submitButton' onClick={()=> setEditGroup(true)}>Replace Image</button>
              </div>
            </div> :
            <div>
              <ImageUpload text={"Upload a new Group Photo from your files."} setFile={setGroupPhoto}/>
              <div>
                <button className='submitButton' onClick={()=> setEditGroup(false)}>Cancel Upload</button>
              </div>
            </div>
          }
          {!editTitlePhoto ?
            <div>
              <img className='image' alt='Event' src={props.band.titlePhoto} />
              <div>
                <button className='submitButton' onClick={()=> setEditTitle(true)}>Replace Image</button>
              </div>
            </div> :
            <div>
              <ImageUpload text={"Upload a new Title Photo from your files."} setFile={setTitlePhoto}/>
              <div>
                <button className='submitButton' onClick={()=> setEditTitle(false)}>Cancel Upload</button>
              </div>
            </div>
          }
          <p className="cardHeader">Spotify Track:</p>
          <input className="eventInput" value={spotify} onChange={e => setSpotify(e.target.value)} />
          <p className="cardHeader">Website:</p>
          <input className="eventInput" value={website} onChange={e => setWebsite(e.target.value)} />
          <p className="cardHeader">Facebook:</p>
          <input className="eventInput" value={facebook} onChange={e => setFacebook(e.target.value)} />
          <p className="cardHeader">Twitter:</p>
          <input className="eventInput" value={twitter} onChange={e => setTwitter(e.target.value)} />
          <p className="cardHeader">Instagram:</p>
          <input className="eventInput" value={instagram} onChange={e => setInstagram(e.target.value)} />
          <div className='buttonDiv'>
            <button className="submitButtonCard" onClick={confirmSubmit}>Submit Band</button>
            <button className="toggleButtonCard" onClick={() => setEdit(false)}>Back To View</button>
          </div>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
        </div> : <BandCard band={props.band} />
      }
    </div>
  )
}

export default EditBandCard;
