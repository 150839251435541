import React from 'react';
import Moment from 'react-moment';

const BookingCell = props => {
  return(
    <div onClick={() => props.setTheBooking(props.b,props.i)} key={props.i} className={props.i === props.bookingIndex ? 'tableOuterRowFocus': 'tableOuterRow'}>
        <div className="tableInnerRow"><div className='tableCell tableHead'><Moment format="hh:mm a">{props.b.slotStartTime}</Moment></div></div>
        <div className="tableInnerRow">
          {!props.b.available ?
            <div className={props.i === props.bookingIndex ? 'tableCellFocus' : 'tableCell'}>Booked</div> : <div className={props.i === props.bookingIndex ? 'tableCellFocus' : 'tableCell'}>No User</div>
          }
          <div className='tableCell tableLink'>Click</div>
        </div>
    </div>
  )
}

export default BookingCell;
