import React from 'react';

const BookingUser = props => {
  return(
    <div>
      <p className="bookingUserP" onClick={props.selectUser}>{props.user.email}</p>
    </div>
  )
}

export default BookingUser;
