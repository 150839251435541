import React,{useState, useEffect} from 'react';
import '../App.css';
import {Link, Redirect} from 'react-router-dom'
import {useStore} from '../store.js';
import CreateImage from "./imageComponents/CreateImage"
import ViewImages from "./imageComponents/ViewImages"
import getRequest from "../functions/getRequest"

const Images = () => {
  const [index, setIndex] = useState(1);
  const user = useStore(state => state.user);
  const imagesFetched = useStore(state => state.imagesFetched);
  const setImagesFetched = useStore(state => state.setImagesFetched);
  const setImages = useStore(state => state.setImages);
  useEffect(() => {
    console.log(imagesFetched);
    if (!imagesFetched) {
      const getImages = async () => {
        getRequest("image", {}, {})
          .then(res => {
            setImagesFetched(true);
            setImages(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getImages();
    }
  },[imagesFetched, setImagesFetched, setImages]);
  return(
    <div>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      {user.role === 'admin' || user.role === 'band' ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      <h1>Image Gallery</h1>
      <div>
        <Link to='/'>Back To Dashboard</Link>
          <div className='flexRow'>
            <p onClick={() => setIndex(1)} className='eventTopLink'>Create A New Image</p>
            <p onClick={() => setIndex(2)} className='eventTopLink'>View And Edit Imaes</p>
          </div>
          {index === 0 ? <p>Click an action above to work on an event.</p> :
          index === 1 ? <CreateImage /> :
          <ViewImages />
          }
      </div>
    </div>
  )
}

export default Images
