import React from 'react';

const BookingAvailability = props => {
  return(
    <>
      {!props.available ?
        <p className='eventInfo'><strong>Meeting with user: {props.user}</strong></p> :
        <div>
          <p className='eventInfo'>This time slot is not yet booked</p>
          <button className='submitButton' onClick={props.next}>Next Booked Slot</button>
        </div>}
    </>
  )
}

export default BookingAvailability;
