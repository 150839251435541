import React, {useState} from 'react';
import UserCard from './UserCard';
// import UserCard from './UserCard';

const GetUser = props => {
  const [email, setEmail] = useState('');
  // const [message, setMessage] = useState('');
  // const [messageType, setMessageType] = useState('messageSuccess');
  // const messageTimer = (message,type) => {
  //   setTimeout(() => {
  //     setMessage('');
  //   }, 5000);
  //   setMessage(message);
  //   setMessageType(type)
  // }
  // const getUser = async () => {
  //   let re = /\S+@\S+\.\S+/;
  //   if(!re.test(email)) {
  //     messageTimer("Email must be in email format.");
  //     setMessageType('messageError');
  //     return;
  //   }
  //   let newUser = props.users.find(x => email === x.email);
  //   if (user) {
  //     setUser(newUser);
  //     messageTimer('User was fetched.', 'messageSuccess');
  //   }else {
  //     messageTimer("Could not find user", 'messageError');
  //   }
  // }
  console.log(props.users);
  return(
    <div>
      <input className='eventInput' value={email} placeholder='Enter Users Email' onChange={e => setEmail(e.target.value)}/>
      <p style={{fontSize:"1.25rem"}}>Email Filter</p>
      <p style={{marginBottom:"5%", fontSize:"1.5rem"}}><strong>{email.length > 0 ? email : "No Filter"}</strong></p>
      {props.users.map((user, i) =>
        <div>
          {user.email.toLowerCase().includes(email.toLowerCase()) ?
            <UserCard user={user}/> : null
          }
        </div>
      )}
    </div>
  )
}

export default GetUser;
