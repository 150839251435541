import React, {useState, useEffect} from 'react';
import {useStore} from '../../store.js';
import Moment from 'react-moment';
import Timer from './Timer.js';
import BookingCell from './BookingCell';
import BookingAvailability from './BookingAvailability';
import ChatInProgress from './ChatInProgress';

const VideoEventCard = props => {
  const [link, setLink] = useState(props.event.booking[0].link);
  const [booking, setBooking] = useState(props.event.booking[0]);
  const [bookingIndex, setIndex] = useState(0);
  const [toggleVideo, setToggleVideo] = useState(false);
  const bookingDate = new Date(booking.slotStartTime);
  const duration = parseInt(props.event.meetingDuration);
  const endDate = new Date(bookingDate.getTime() + (duration+2)*60000);
  const users = useStore(state => state.users);
  const [bookingUser, setBookingUser] = useState('');
  const [eventInProgress, setEventInProgress] = useState(false);
  const [hideSlotInfo, setHideSlotInfo] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('messageSuccess');
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type);
  }
// Gets tbe user info for the booking.
  useEffect(() => {
    if (!booking.available && users !== undefined) {
      const userObj = users.find(u => u._id === booking.user);
      if (userObj !== undefined) setBookingUser(userObj.name);
    }
  },[users, booking]);
  // Checks whether event is in progress and sets eventInProgress to true if so.
  useEffect(() => {
    if (new Date() >= new Date(props.event.booking[0].slotStartTime)) {
      setEventInProgress(true);
    }
  },[props.event.booking])
  // Function to set the focussed booking on click.
  const setTheBooking = (b,i) => {
    console.log(b)
    setToggleVideo(false);
    setIndex(i);
    setLink(b.link);
    setBooking(b);
  }
  // Goes to the next time slot with a user booked to chat. Shows a message if no more slots are booked.
  const goToNextBookedSlot = () => {
    for (let i=bookingIndex+1; i < props.event.booking.length; i++) {
      if (!props.event.booking[i].available && new Date(props.event.booking[i].slotStartTime) > new Date()) {
        setTheBooking(props.event.booking[i], i);
        return;
      }
      messageTimer('Sorry could not find any booked slots after the booking you are viewing.');
      setMessageType('messageError');
    }
  }
  // TODO: Do not allow enter room before the event is in Progress
  // Currently enters room if booking is in future for testing purposes.
  const enterRoom = () => {
    if (new Date(bookingDate.setMinutes(bookingDate.getMinutes() + parseInt(props.event.meetingDuration) + 10)) > new Date()) {
      setToggleVideo(true);
    } else {
      messageTimer('This time slot is too old to join the room anymore...', 'messageError');
    }
  }
  let timeSlotButtonText = hideSlotInfo ? 'Show Time Slots' : 'Hide Time Slots';
  return(
    <div className='videoEventCard'>
      <p className='eventTitle' style={{ color:'lightgoldenrodyellow',fontSize:'1.6rem', fontWeight:'bold'}}>{props.event.title}</p>
      <p className='eventSubtitle'><Moment format="ddd MMM DD YYYY">{props.event.date}</Moment></p>
      {!hideSlotInfo ?
        <div className='table' >
          {props.event.booking.map((b, i) =>
            <BookingCell key={i} b={b} i={i} setTheBooking={setTheBooking} bookingIndex={bookingIndex} />
          )}
        </div> : null
      }
      <button className='submitButton longButton' onClick={() => setHideSlotInfo(!hideSlotInfo)}>{timeSlotButtonText}</button>
      <p className='eventInfo'>Event Booking # {bookingIndex + 1}: <Moment format="h:mm A">{booking.slotStartTime}</Moment> to <Moment add={{ minutes: parseInt(props.event.meetingDuration) }} format="h:mm A">{booking.slotStartTime}</Moment></p>
      <BookingAvailability next={goToNextBookedSlot} user={bookingUser} available={booking.available} />
      {eventInProgress ? <p className='eventInfo' style={{color: `aqua`}}>Event Currently In Progress</p> :
        <Timer text="Time Until Event Starts" end={new Date(booking.slotStartTime)} updateEventInProgress={() => setEventInProgress(true)} eventStartTimer={true} />}
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      {booking.link !== undefined && booking.link !== null ?
        <div>
          {!toggleVideo ?
            <button className='submitButton' onClick={() => enterRoom()}>Join Room</button> :
            <button className='submitButton' onClick={() => setToggleVideo(false)}>Leave Room</button>
          }
        </div> : <p className='eventInfo'>No one has registered for this time slot, so no room has been created.</p>}
      <ChatInProgress bookingDate={bookingDate} bookings={props.event.booking} setTheBooking={setTheBooking} duration={duration}
        user ={bookingUser} index={bookingIndex} end={endDate} link={link} next={goToNextBookedSlot} toggle={toggleVideo}/>
    </div>
  )
}

export default VideoEventCard;
