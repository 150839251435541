import React, {useEffect} from 'react';
import '../App.css';
import './componentStyling/Video.css';
import {Link, Redirect} from 'react-router-dom';
import {useStore} from '../store.js';
import getRequest from '../functions/getRequest';
import VideoEventCard from './videoComponents/VideoEventCard';

const Video = () => {
  const user = useStore(state => state.user);
  const users = useStore(state => state.users);
  const setUsers = useStore(state => state.setUsers);
  const usersFetched = useStore(state => state.usersFetched);
  const setUsersFetched = useStore(state => state.setUsersFetched)
  const aAEvents = useStore(state => state.aAEvents);
  const aAEventsFetched = useStore(state => state.aAEventsFetched);
  const setAAEventsFetched = useStore(state => state.setAAEventsFetched);
  const setAAEvents = useStore(state => state.setAAEvents);
  var today = new Date();
  today = new Date(today).setHours(0,0,0,0);
  useEffect(() => {
    if (!aAEventsFetched) {
      const getAAEvents = async () => {
        getRequest("allaccess", {}, {})
          .then(res => {
            let newArr = res.data.data;
            setAAEventsFetched(true);
            setAAEvents(newArr);
          })
          .catch(err => {
            console.log(err.message);
          })
    }
    getAAEvents();
    }
  }, [aAEventsFetched, setAAEventsFetched, setAAEvents, user.toker]);
  useEffect(() => {
    if (!usersFetched) {
      const getUsers = async () => {
        getRequest("users", {}, {Authorization: `Bearer ${user.token}`})
          .then(res => {
            let newArr = res.data.data;
            console.log(newArr);
            setUsersFetched(true);
            setUsers(newArr);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getUsers();
    }
  }, [usersFetched, setUsersFetched, setUsers, user.token]);
  let myChats = [];
  console.log(users);
  if (aAEvents.length > 0) {
    myChats = aAEvents.filter(event => event.band === user.band);
    myChats = aAEvents.filter(event => new Date(today) <= new Date(event.date));
    myChats.forEach(event => {
      event.booking.sort(function(a,b){return new Date(a.slotStartTime) - new Date(b.slotStartTime)});
    })
  }
  return (

    <div>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      {user.role === 'band' || user.role === 'admin' ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      <div>
        <Link to='/'>Back To Dashboard</Link>
      </div>
      <div>
        <p>Upcoming Events:</p>
        {myChats.length > 0 ?
          <div>
            {myChats.map((event, i) =>
              <div key={i}>
                <VideoEventCard event={event} flag={usersFetched}/>
              </div>
            )}
          </div>
          : null}
      </div>
    </div>
  )
}

export default Video;
