import React, {useState} from 'react';
import {useStore} from '../../store.js';
import EventCard from './EventCard';

const ViewEventsComp = () => {
  const events = useStore(state => state.events);

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [filter, setFilter] = useState("")
  console.log(events);
  return (
    <div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
      : null}
      <p style={{fontSize:"1.25rem"}}>Event Filter</p>
      <p style={{fontSize:"1.5rem"}}><strong>{filter.length > 0 ? filter : "No Filter"}</strong></p>
      <input className='eventInput' value={filter} placeholder='Filter Events' onChange={e => setFilter(e.target.value)}/>
      {events.length > 0 ? events.map((e, i) =>
        e.name.toLowerCase().includes(filter) ?
          <EventCard setMessType={m => setMessageType(m)} setMess={m => setMessage(m)} event={e} key={i}/> : null
        ) : null}
    </div>
  )
}

export default ViewEventsComp;
