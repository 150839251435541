import React,{useState} from 'react';
import getDate from "../../functions/getDate";
import Moment from 'react-moment';
import { DatePicker } from 'react-rainbow-components';
import TimePicker from 'react-time-picker';

const EditDateComp = props => {
  const [eventDate, setDate] = useState(new Date(props.date.date));
  const [link, setLink] = useState(props.date.link);
  const [venue, setVenue] = useState(props.date.venue);
  const [location, setLocation] = useState(props.date.location);
  const [time, setTime] = useState("18:00");
  const [time2, setTime2] = useState("19:00");
  const setTheLocation = value => {
    setLocation(value);
    props.updateLocation(props.index, value)
  }
  const setTheLink = value => {
    setLink(value);
    props.updateLink(props.index, value)
  }
  const setTheVenue = value => {
    setVenue(value);
    props.updateVenue(props.index, value)
  }
  const setTheDate = value => {
    setDate(value);
    props.updateDate(props.index, value, time, time2)
  }
  const setStart = value => {
    setTime(value)
    props.updateStart(props.index, value)
  }
  const setEnd = value => {
    console.log('ko')
    setTime2(value)
    props.updateEnd(props.index, value)
  }
  return (
    <div className='datesDiv'>
      <p className='formSectHeader'><strong>Location:</strong></p>
      <input className='editInput' value={location} onChange={e => setTheLocation(e.target.value)} />
      <p className='formSectHeader'><strong>Venue:</strong></p>
      <input className='editInput' value={venue} onChange={e => setTheVenue(e.target.value)} />
      <p className='formSectHeader'><strong>Link:</strong></p>
      <input className='editInput' value={link} onChange={e => setTheLink(e.target.value)} />
      <p className='formSectHeader'><strong>Date</strong> {getDate(props.date.date)}</p>
      <DatePicker className="datepicker" value={eventDate} onChange={date => setTheDate(date)} />
      <p className='formSectHeader'><strong>Time:</strong> From <Moment format="hh:mm a">{props.date.startTime}</Moment> to <Moment format="hh:mm a">{props.date.endTime}</Moment></p>
      <div className='outerTimeDiv'>
        <div className='innerTimeDiv'>
          <span className='spanHeader'>Start Time</span>
          <TimePicker
          onChange={val => setStart(val)}
          value={time}
          className='timePicker'
          label='Start'
          />
        </div>
        <div className='innerTimeDiv'>
          <span className='spanHeader'>End Time</span>
          <TimePicker
          onChange={val => setEnd(val)}
          value={time2}
          className='timePicker'
          label='End'
          />
        </div>
      </div>
      <button className='' onClick={() => props.removeDate(props.date._id)}>Remove</button>
    </div>
  )
}

export default EditDateComp
