import React from 'react';
import {useStore} from '../../store.js';
import AdCard from "./AdCard"

const ViewAds = ()=> {
  const ads = useStore(state => state.ads);
  return (
    <div>
      <h1>Edit Ads</h1>
      <p>Viewing and Deleting ads are now live. Check back soon for editing/updating ads</p>
      {ads.length > 0 && ads.length > 0 ? ads.map((a, i) => <AdCard ad={a} key={i}/>) : null}

    </div>
  )
}

export default ViewAds
