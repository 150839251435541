import React from 'react';
import Timer from './Timer';
import VideoFrame from './VideoFrame';

const ChatInProgress = props => {
  console.log(props.bookings[props.index + 1]);
  return(
    <>
    {props.toggle ?
      <div className='inEventInfoDiv'>
        {new Date(props.bookingDate.setMinutes(props.bookingDate.getMinutes() + props.duration + 2)) < new Date() ?
          <button className='submitButton' onClick={props.next}>Next Booked Slot</button> : null
        }
        <button className='submitButton' onClick={() => props.setTheBooking(props.bookings[props.index + 1], props.index + 1)}>Next Slot</button>
        <p>Talking to: <strong>{props.user}</strong></p>
        <Timer text='Time remaining in room:' end={props.end} eventStartTimer={false}/>
        <VideoFrame info={props} />
      </div> : null}
    </>
  )
}

export default ChatInProgress;
