import React, {useState, useEffect} from 'react';
import '../index.css';
import '../App.css';
import Dashboard from './Dashboard';
import Login from './Login';
import {useStore} from '../store.js';
import getRequest from '../functions/getRequest';
import postRequest from '../functions/postRequest';
import Loader from './Loader';

const LandingPage = () => {
  const user = useStore(state => state.user);
  const setUser = useStore(state => state.setUser);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("")
  const messageTimer = message => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
  }
  const toggle = (email, pass) => {
    setLoading(true);
    setEmail(email);
    setPass(pass)
  }
useEffect(() => {
    let token;
    const logIn = async () => {
      setTimeout(() => {
        postRequest('auth/login', {email: email, password:pass}, {"content-type": "application/json"})
          .then(async res => {
            token = res.data.token;
            localStorage.setItem('toke', token)
            return getRequest('auth/me',{},
            {Authorization: `Bearer ${res.data.token}`})
          })
          .then(res => {
            if (res.data.data.role !== 'admin' && res.data.data.role !== 'band') {
              setMessage("Sorry you can't access this site. Thanks for using the app tho!!");
              setMessageType('messageError');
              return;
            }
            const userInfo = {
              name:res.data.data.name,
              email:res.data.data.email,
              username:res.data.data.username,
              avatar: res.data.data.avatar,
              authed:true,
              role:res.data.data.role,
              token:token,
              band: res.data.data.role === 'band' ? res.data.data.band : null
            }
            setUser(userInfo);
            messageTimer("Successfully logged in!");
            setMessageType('messageSuccess');
          })
          .catch(err => {
            messageTimer("Invalid login");
            setMessageType('messageError');
          })
        setLoading(false)
      }, 1000)
    }
    if (loading) {
      logIn();
    }
  }, [loading, email, pass, setUser])
  return(
    <div>
      {!loading ?
          <div>{user.authed ?
            <Dashboard /> : <Login toggleLoading={toggle}/>
          }</div> : <Loader name={"Logging In"} />
      }
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
      : null}
    </div>
  )
}
export default LandingPage;
