import React, {useState} from 'react';
import ViewEventsComp from "./ViewEventsComp";
import ViewAllAccessComp from "../allAccessComponents/ViewAllAccessComp";

const ViewEvents = () => {
  const [toggle, setToggle] = useState(0);
  return(
    <div>
      <h2>Event Type</h2>
      <div className='buttonDiv'>
          <button className='eventTypeButton' onClick={() => setToggle(0)}>Events</button>
          <button className='eventTypeButton' onClick={() => setToggle(1)}>All Access</button>
      </div>
      {toggle === 0 ? <ViewEventsComp /> : <ViewAllAccessComp />}
    </div>
  )
}

export default ViewEvents;
