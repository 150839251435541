import React, {useState, useRef,useEffect} from 'react';
import Select from 'react-select'
import {useStore} from '../../store.js';
import postRequest from '../../functions/postRequest';
import putRequest from '../../functions/putRequest';
import getRequest from '../../functions/getRequest';
import DateTime from '../helperComponents/DateTime.js';
import ImageDisplay from '../helperComponents/ImageDisplay';
import ImageUpload from '../helperComponents/ImageUpload';
import Moment from 'react-moment';
import validator from 'validator'

const CreateRegularEvent = props => {
  const selectInputRef = useRef();
  // App state variables
  const bands = useStore(state => state.bands);
  const user = useStore(state => state.user);
  const events = useStore(state => state.events);
  const removeAllEvents = useStore(state => state.removeAllEvents);
  const setEventsFetched = useStore(state => state.setEventsFetched);
  // Options for all the selectors
  const options2 = [
    {value:'band', label:'Artist'},
    {value:'mkr', label:'Label'}
  ]
  const options3 = [
    {value:'twitch', label:'Twitch'},
    {value:'inPerson', label:'In Person'}
  ]
  // Input variables
  const [time, setTime] = useState('18:00');
  const [time2, setTime2] = useState('19:00');
  const [dates, setDates] = useState([]);
  const [gallery, setGallery] = useState(0)
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState('');
  const [venue, setVenue] = useState('');
  const [location, setLocation] = useState('')
  const [link, setLink] = useState('');
  const [desc, setDesc] = useState('');
  const [streamType, setStream] = useState('');
  const [type, setType] = useState('');
  const [region, setRegion] = useState('')
  const [photo, setPhoto] = useState(0);
  const [labelImage, setLabelImage] = useState(false);
  const [bandSelected, selectBand] = useState(-1);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('messageSuccess');
  // Function to remove error message after 5 seconds.
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const images = useStore(state => state.images);
  const imagesFetched = useStore(state => state.imagesFetched);
  const setImagesFetched = useStore(state => state.setImagesFetched);
  const setImages = useStore(state => state.setImages);
  useEffect(() => {
    if (!imagesFetched) {
      const getImages = async () => {
        getRequest("image", {}, {})
          .then(res => {
            let newArr = res.data.data;
            setImagesFetched(true);
            setImages(newArr);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getImages();
    }
  },[imagesFetched, setImagesFetched, setImages]);
  const resetInputs = () => {
    setDates([])
    setDate(new Date())
    setName('');
    setDesc('');
    setStream('');
    setFile(null);
    setRegion('')
  }
  // Checks if all inputs have values.
  // Checks type of event then sends post request accordingly.
  const handleSubmit = async () => {
    if (name.length < 5 || desc.length < 10) {
      messageTimer('Event name must be at least 5 character and/or description must be at least 10.', 'messageError')
      return;
    }
    if (bandSelected < 0 && streamType === 'band') {
      messageTimer('Please select the artist(s) featured in the event.', 'messageError');
      return;
    }
    if (streamType === '') {
      messageTimer('Please set stream type', 'messageError');
      return;
    }
    const start = new Date(date.toString().substring(0,16) + time);
    const end = new Date(date.toString().substring(0,16) + time2);
    if (end <= start) {
      messageTimer("The start time must be before the end time.", 'messageError');
      return;
    }
    if (file === null && !photo && (bandSelected < 0 || bandSelected === undefined) && labelImage) {
      messageTimer('You must select a file or toggle back to the default image.', 'messageError');
      return;
    }
    if (bandSelected < 0 && labelImage && file === null) {
      messageTimer("You must select an image for this Label event", "messageError")
      return;
    }
    if (bandSelected > 0 && photo === 3 && file === null) {
      messageTimer("You must select an image for this Artist event", "messageError")
      return;
    }
    // Figure out which photo to use
    const photoString = bandSelected >= 0 && photo === 0 ? bands[bandSelected].groupPhoto :
    bandSelected >= 0 && photo === 1 ? bands[bandSelected].titlePhoto :
    bandSelected > 0 && photo === 2 ? images[gallery].url :
    bandSelected < 0 && !labelImage ? images[gallery].url : null
  // Send Request
    return await postRequest('events/', {
      band:bandSelected >= 0 ? bands[bandSelected].id : null,
      photo:photoString,
      startDate:dates.date,
      date:dates[dates.length - 1].date,
      dates:dates,
      eventLocation:region,
      name:name,
      description:desc,
      streamType:streamType,
      type:type,
      venue:'PunkHubLive'
    }, {Authorization: `Bearer ${user.token}`})
      .then(async res => {
        messageTimer('Event Created!!!', 'messageSuccess');
        if (photoString === null) {
          uploadImage(res.data.event._id, start, end)
        } else {
          messageTimer('Event Created!!!', 'messageSuccess');
          updateEvents(res);
        };
        resetInputs();
      })
      .catch(err => {
        console.log(err.response)
        messageTimer('Event not created, Reason: \n' + err.response.data.error, 'messageError');
      })
  }
  const updateEvents = res => {
    removeAllEvents();
    setEventsFetched(false);
  }
  // Call this only when a local upload for image is being used. Otherwise, only the location string is needed
  // for files already stored on google cloud.
  const uploadImage = async (id, start, end) => {
    return await putRequest(
        `events/${id}/photo`,
        {photo: file.base64.split(',')[1]},
          {Authorization: `Bearer ${user.token}`})
      .then(res => {
        console.log(res)
        updateEvents(res);
        messageTimer('Image successfully uploaded', 'messageSuccess');
      })
      .catch(err => {
        messageTimer('Image not uploaded, Reason: ' + err.message, 'messageError');
      })
  }
  const clearBand = () => {
    selectBand(-1);
    selectInputRef.current.select.setValue(-1);
  }
  const setPhotoFunc = () => {
    let num = photo + 1
    if (photo === 3) {
      setPhoto(0)
    } else {
      setPhoto(num)
    }
  }
  const setPrevGallery = () => {
    if (images.length > 0) {
      let index = gallery;
      index--;
      if (index < 0) {
        index = images.length - 1
        setGallery(index);
      }else {
        setGallery(index);
      }
    }else {
      return
    }
  }
  const setNextGallery = () => {
    if (images.length > 0 && images.length > 0) {
      let index = gallery;
      index++
      if (index >= images.length) {
        setGallery(0);
      }else {
        setGallery(index);
      }
    }else {
      return
    }
  }
  const addADate = () => {
    let linkUrl;
    if (link.length > 0 && !validator.isURL(link)) {
      messageTimer("Please add a proper url.", "messageError")
      return
    } else {
      linkUrl = link === '' ? 'https://manickatrecords.com' : link
    }
    console.log(events);
    if(location.length > 0 && venue.length > 0) {
      const start = new Date(date.toString().substring(0,16) + time);
      const end = new Date(date.toString().substring(0,16) + time2);
      if (end <= start) {
        messageTimer("The start time must be before the end time.", 'messageError');
        return;
      }
      let copyArr = dates;
      copyArr.push({
        location:location,
        venue:venue,
        link:linkUrl,
        date:date,
        startTime:start,
        endTime:end,
      });
      setDates(copyArr.sort((a,b)  => a.date - b.date));
      clearDateInfo()
    } else {
      messageTimer("Please add data for all required inputs.", "messageError")
      return
    }
  }
  const clearDateInfo = () => {
    setLocation("");
    setLink("");
    setVenue("");
    setDate(new Date());
  }
  const removeDate = index => {
    setDates(dates.filter((d,i) => i !== index))
  }
  console.log(events);
  return (
    <div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      <input className='eventInput' placeholder='Event Name' value={name} onChange={e => setName(e.target.value)}/>
      <br />
      <Select className='select streamSelect' options={options2} placeholder='Stream Type' onChange={selection => setStream(selection.value)} />
      {streamType === "band" ?
        <div>
          <Select className='select' options={props.bandList} ref={selectInputRef} placeholder='Select The Band' onChange={selection => selectBand(selection.value)} />
          <button onClick={clearBand}>Remove Band</button>
          {bandSelected < 0 || bandSelected === undefined ? <p>You Must select a band for band type events.</p> : null}
        </div> : streamType !== "mkr" && streamType !== "band" ?
          <p>Please select the Stream type above.</p> : null
      }
      {bandSelected >= 0 ?
        <div className='photoDiv'>
          <button className="toggleButtonCard" onClick={setPhotoFunc}>Toggle Photo</button>
          {photo === 0 ?
            <ImageDisplay src={bands[bandSelected].groupPhoto} text={'Group Image Selected'} /> : photo === 1 ?
            <ImageDisplay src={bands[bandSelected].titlePhoto} text={'Title Image Selected'} /> : photo === 2 ?
            <div>{images.length > 0 && images.length > 0 ?
                <div>
                  <h2>Image Gallery Selected</h2>
                  <h4>{images[gallery].name}</h4>
                  <div className="galleryDiv">
                    <p onClick={setPrevGallery} className="galleryButton">Previous</p>
                    <ImageDisplay src={images[gallery].url} text={""}/>
                    <p onClick={setNextGallery} className="galleryButton">Next</p>
                  </div>
                </div> : <p>The Image Gallery is Empty</p>}

            </div> :
            <div>
              <ImageUpload setFile={setFile} text={'Local File Upload Selected'} />
              <button onClick={() => setFile(null)}>Remove</button>
              {file !== null ?
                <ImageDisplay src={file.base64} text={'Event Image Selected'} /> : null
              }
            </div>
          }
        </div> : (bandSelected < 0 || bandSelected === undefined) && streamType === 'mkr' ?
        <div>
          <button className="toggleButtonCard" onClick={() => setLabelImage(!labelImage)}>Toggle Photo</button>
          {!labelImage ?
            <div>{images.length > 0 && images.length > 0 ?
                <div>
                  <h2>Image Gallery Selected</h2>
                  <h4>{images[gallery].name}</h4>
                  <div className="galleryDiv">
                    <p onClick={setPrevGallery} className="galleryButton">Previous</p>
                    <ImageDisplay src={images[gallery].url} text={""}/>
                    <p onClick={setNextGallery} className="galleryButton">Next</p>
                  </div>
                </div> : <p>The Image Gallery is Empty</p>}

            </div> :
            <div>
              <ImageUpload setFile={setFile} text={'Local File Upload Selected'} />
              <button onClick={() => setFile(null)}>Remove</button>
              {file !== null ?
                <ImageDisplay src={file.base64} text={'Event Image Selected'} /> : null}
            </div>
          }
        </div> : null
      }
      <br /><br />
      <Select className='select typeSelect' options={options3} placeholder='Regular Event Type' onChange={selection => setType(selection.value)} />
      <textarea className='eventInput eventDesc' placeholder='Event Description' value={desc} onChange={e => setDesc(e.target.value)} />
      <input className='eventInput' placeholder='Event Region' value={region} onChange={e => setRegion(e.target.value)}  />
      <div className='cardDiv'>
        <p className="eventTitle">Individual Date Information</p>
        <input className='eventInput' placeholder='Location' value={location} onChange={e => setLocation(e.target.value)}/>
        <input className='eventInput' placeholder='Venue' value={venue} onChange={e => setVenue(e.target.value)}/>
        <p>Set the event link below, if it is left empty the event link will default to https://manickatrecords.com</p>
        <input className='eventInput' placeholder='Event Link' value={link} onChange={e => setLink(e.target.value)}/>
        <DateTime time={time} time2={time2} date={date} setDate={setDate} setTime={setTime} setTime2={setTime2}/>
        {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
        <button className='invToggleButtonCard' onClick={addADate}>Add Date Info</button>
      </div>
      <div className="cardDiv">
        <p className="eventTitle">Dates</p>
        {dates.map((d,i) => (
          <div style={{background:"white",color:"black", padding:"1%", marginBottom:"2%"}} key={i}>
            <p>Date #{i+1}</p><p> <strong>Location:</strong> {d.location} <strong>Venue:</strong> {d.venue}</p>
            <p><strong>Link:</strong> {d.link}</p>
            <Moment format="LL">{d.date}</Moment>
            <p>{time}-{time2}</p>
            <button className='submitButton' onClick={() => removeDate(i)}>Remove</button>
          </div>
        ))}
      </div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      <button className='submitButton' onClick={handleSubmit}>Submit</button>
    </div>
  )
}

export default CreateRegularEvent;
