import React from 'react';
import FileBase64 from 'react-file-base64';

const ImageUpload = props => {
  return(
    <div style={{marginBottom:0}}>
      <p style={{fontSize:"1.3rem", marginTop:'6%'}}><strong>{props.text}</strong></p>
      <FileBase64
        multiple={ false }
        onDone={file => props.setFile(file)}
        style={{color:'black'}}
      />
    </div>
  )
}

export default ImageUpload;
