import React, {useEffect} from 'react';
import {useStore} from '../store.js';
import {Link, Redirect} from 'react-router-dom';
import getRequest from '../functions/getRequest';
import { CSVLink } from "react-csv";
import moment from 'moment';

const ExportData = () => {
  const user = useStore(state => state.user);
  const users = useStore(state => state.users);
  const userNamesEmails = [];
  const setUsers = useStore(state => state.setUsers);
  const usersFetched = useStore(state => state.usersFetched);
  const setUsersFetched = useStore(state => state.setUsersFetched);
  useEffect(() => {
    if (!usersFetched) {
      const getUsers = async () => {
        getRequest("users", {}, {Authorization: `Bearer ${user.token}`})
          .then(res => {
            let newArr = res.data.data;
            setUsersFetched(true);
            setUsers(newArr);
          })
          .catch(err => {
            console.log(err.message);
          })
    }
    getUsers();
    }
  }, [usersFetched, setUsersFetched, setUsers, user.token]);
  console.log(users)

  for(var i = 0; i < users.length; i++) {
    userNamesEmails[i] = users[i].name.replace("\"", "") + " : " + users[i].email.replace("\"", "");
  }

  return(
    <div className='exportDiv'>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }} />
      }
      <h1>Exports</h1>
        <Link to='/' className='navLink'>Back to Dashboard</Link>
        <p>Exports CSV File of Names and Emails in (name : email) format.</p>
        {!users.length > 0 ? <p>Users have not been fetched yet</p>:
          <CSVLink data={JSON.stringify(userNamesEmails)}
                   filename={"userNames-userEmails" + moment().format("MM-DD-YYYY") + ".csv"}
                  >User Data</CSVLink>
      }
    </div>
  )
}
export default ExportData;
