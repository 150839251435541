import React from 'react';
import {useStore} from '../../store.js';
import ImageCard from "./ImageCard"

const ViewImages = ()=> {
  const images = useStore(state => state.images);
  return (
    <div>
      <h1>View Image Gallery</h1>
      {images.length > 0 ? images.map((a, i) => <ImageCard image={a} key={i}/>) : null}

    </div>
  )
}

export default ViewImages;
