import axios from 'axios'
const putRequest = async (route, data, headerData) => {
  const put = await axios.put(`${process.env.REACT_APP_API_URL + route}`,
  data,
  {headers:headerData}
  )
  return put
}

export default putRequest;
