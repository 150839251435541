import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';
import {useStore} from '../store.js';

const Dashboard = () => {
  const user = useStore(state => state.user)
  return (
    <div className='dashDiv'>
      <h1>{user.role.toUpperCase()} Dashboard</h1>
      <h2>Hi, {user.name}</h2>
        {user.role === 'admin' ?
          <div className="dashLinkDiv">
            <Link to='/users' className='eventTopLink'>Users Section</Link>
            <Link to='/bands' className='eventTopLink'>Bands Section</Link>
            <Link to='/events' className='eventTopLink'>Events Section</Link>
            <Link to='/advertising' className='eventTopLink'>Advertising</Link>
            <Link to='/images' className='eventTopLink'>Image Gallery</Link>
            <Link to='/video' className='eventTopLink'>Video Section</Link>
            <Link to='/exportdata' className='eventTopLink'>Exports Section</Link>
            <Link to='/chat' className='eventTopLink'>Chats Section</Link>
          </div> :
          <div className="dashLinkDiv">
            <Link to='/video' className='eventTopLink'>Video</Link>
          </div>
        }
    </div>
  )
}
export default Dashboard;
