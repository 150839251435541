import React, {useEffect, useState, useRef} from 'react';
import '../App.css';
import {Link, Redirect} from 'react-router-dom';
import {useStore} from '../store.js';
import getRequest from '../functions/getRequest';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert';

import socketio from "socket.io-client";
// const LOCALENDPOINT = 'http://localhost:5000'
// import { socket, emitMessage, socketConnection} from '../context/socket';
// import socketio from "socket.io-client";
//
// const socket = socketio.connect(process.env.REACT_APP_SOCKET_URL);
// const connect = () => {
//   socketConnection('jkljjlk');
// }
let socket;
export const emitMessage = (message, user) => {
  const msg = {
    text: message,
  }
  socket.emit('chat message', {msg: msg, user: user, system: true});
}
export const emitDelete = (message) => {
  console.log(message);
  socket.emit('delete message', {msg: message});
}
const Chat = props => {
  const user = useStore(state => state.user);
  const setChatsFetched = useStore(state => state.setChatsFetched);
  const chatsFetched = useStore(state => state.chatsFetched);
  const setChats = useStore(state => state.setChats);
  const addMessage = useStore(state => state.addMessage);
  const chats = useStore(state => state.chats);
  const [text, setText] = useState('');
  const bottomRef = useRef(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const messageTimer = (message, type, time=5000) => {
    setTimeout(() => {
      setMessage('');
    }, time);
    setMessage(message);
    setMessageType(type)
  }
  const room = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? '62e316958ace0c070b7d9a66' : '62e31275a165ed06ba38b782'
  useEffect(() => {
    if (!chatsFetched) {
      const getChats = async () => {
        getRequest(`chats/${room}`, {}, {})
          .then(res => {
            setChatsFetched(true);
            setChats(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getChats();
    }
  },[chatsFetched, setChatsFetched, setChats, room]);
  useEffect(() => {
    socket = socketio(process.env.REACT_APP_SOCKET_URL, {auth: {'token': user.token}, transports: ['websocket']});
    socket.on('connect', () => {
      console.log('connected')
    });

    socket.on('disconnect', () => {
      console.log("disconnectado")
    });
    socket.on('chat message', function(msg) {
        addMessage(msg);
    });
    socket.on("message deleted", () => {
      messageTimer('Message successfully deleted', 'messageSuccess')
      setChatsFetched(false);
    })
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('chat message');
      socket.off('message deleted')
    };
  }, [setChatsFetched, user.token, addMessage]);
  const confirmDelete = message => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this chat message: \n' + message.text,
      buttons: [
        {
          label: 'Yes, delete.',
          onClick: () => deleteMessage(message),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const deleteMessage = m => {
    emitDelete(m)
  }

  const sendMessage = () => {
    emitMessage(text, user);
    setText('');
  }
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [chats]);
  return (
    <div>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      {user.role === 'admin' || user.role === 'band' ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      <h1>Chats</h1>
      <Link to='/' className='navLink'>Back to Dashboard</Link>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
      : null}
      <div className='chatOuterContainer'>
        <h3>Punk Hub Chat</h3>
        <div className='chatBox'>
          {chats.length > 0 ?
            <>
              {chats.map((m, i) => (
                <div className='messageOuterContainer' style={{background: i % 2 === 0 ? 'black' : '#333333', width: '100%'}} key={m._id}>
                  <div className='messageHeaderDiv'>
                    <span className='messageHeaderText'>{m.user.name}</span>
                    <span className='messageHeaderText'><Moment format="LL">{m.createdAt}</Moment></span>
                  </div>
                  <div className="messageContainer">
                    <div className='avatarDiv'>
                      <img alt='user avatar' src={m.user.avatar} width="60" height="60" className="avatar" />
                    </div>
                    <div>
                      <div className='bubble'>
                        <p className="bubbleText" >{m.text}</p>
                        <div className='bubbleFooter'>
                          <span className='bubbleFooterText'><Moment format="hh:mm A">{m.createdAt}</Moment></span>
                          <span onClick={() => {
                            confirmDelete(m);
                          }} className='bubbleFooterText chatDeleteButton'>Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))

              }
            </> : null
          }
          <div ref={bottomRef} />
        </div>
        <div className='messageInputBox'>
          <input className='messageInput' value={text} placeholder='Message Text' onChange={e => setText(e.target.value)} type='text' />
          <span className='messageSendButton' onClick={sendMessage}>Send</span>
        </div>
      </div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
      : null}
    </div>
  )
}

export default Chat;
