import React from 'react';

const VideoFrame = props => {
  const url = props.info.link + "?token=" + localStorage.getItem('toke');
  return (
    <div className='iFrameDiv'>
      <iframe className="iFrame"
          allow="camera;microphone"
          title="Video Call"
          src={url}>
      </iframe>
    </div>
  )
}

export default VideoFrame;
