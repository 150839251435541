import React, {useState} from 'react';
import Moment from 'react-moment';
import {useStore} from '../../store.js';
import postRequest from '../../functions/postRequest';
import putRequest from '../../functions/putRequest';

import BookingUser from "./BookingUser";
import { confirmAlert } from 'react-confirm-alert';
import Loader from "../Loader"

const BookingCard = props => {
  const [showDetails, setShowDetails] = useState(false);
  const users = useStore(state => state.users);
  const user = useStore(state => state.user);
  const [showUsers, setShowUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState('messageSuccess');
  const [loading, setLoading] = useState(false);
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const userLookup = () => {
    let user;
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === props.booking.user) {
        user = users[i].name;
        break;
      }
    }
    return user;
  }
  const confirmClear = () => {
    console.log("in clear")
    confirmAlert({
      title: 'Confirm to Clear Booking',
      message: 'Are you sure you want to clear time slot for \n' + props.eventTitle + "? At " +
      props.booking.slotStartTime+ "\n User: " + userLookup(),
      buttons: [
        {
          label: 'Yes, clear Booking.',
          onClick: () => submitClear(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const confirmSubmit = () => {
    confirmAlert({
      title: 'Confirm to Reserve',
      message: 'Are you sure you want to book slot for \n' + props.eventTitle + "? At " +
      props.booking.slotStartTime+ "\n User: " + selectedUser.name + ", email:" + selectedUser.email,
      buttons: [
        {
          label: 'Yes, update.',
          onClick: () => submitBooking(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const submitBooking = () => {
    setLoading(true)
    postRequest(`allAccess/registerByAdmin/${props.id}`, {
      user:selectedUser._id,
      slotId:props.booking._id
    }, {Authorization: `Bearer ${user.token}`})
      .then(res => {
        messageTimer("The time slot was booked!!", "messageSuccess");
        setLoading(false);
        setSelectedUser({});
        setShowUsers(false);
      })
      .catch(err => {
        messageTimer("Slot was not booked", "messageError");
        setLoading(false);
        console.log(err)
      })
  }
  const submitClear = () => {
    setLoading(true)
    putRequest(`allAccess/registerByAdmin/${props.id}`, {
      user:selectedUser._id,
      slotId:props.booking._id
    }, {Authorization: `Bearer ${user.token}`})
      .then(res => {
        messageTimer("The time slot was cleared!!", "messageSuccess");
        setLoading(false);
        setShowDetails(false);
        console.log("success")
      })
      .catch(err => {
        messageTimer("Slot was not cleared", "messageError");
        setLoading(false)
        console.log(err)
      })
  }
  const cancel = () => {
    setSelectedUser({});
  }
  const userButtonText = showUsers ? "Hide Users" : "Book A User"
  return(
    <div>
      {!showDetails ?
        <div className="bookingInfo">
        {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
        : null}
          {loading ? <Loader name="Updating Event..." /> : null}
          <span><strong>Slot {props.i+1}: </strong> <Moment format="hh:mm a">{props.booking.slotStartTime}</Moment></span>
          {props.booking.available ?
            <div>
              <div>   Not Booked  <button onClick={() => setShowUsers(!showUsers)} className="bookingButtons">{userButtonText}</button>
                {Object.keys(selectedUser).length > 0 ? <div>
                <p><strong>Selected User:{selectedUser.email}</strong></p>
                <button className="bookingButtons" onClick={confirmSubmit}>Book For This User</button>
                <button className="bookingButtons" onClick={cancel}>Cancel</button>
              </div> : null}
              </div>
              {showUsers ?
                <div className="userBookingList">
                    {users.map((u,i) =>
                      <div key={i}><BookingUser user={u} selectUser={() => setSelectedUser(u)}/></div>
                    )}
                </div> : null}
            </div> :
            <span>    Booked  <button className="bookingButtons" onClick={() => setShowDetails(true)} style={{marginLeft:"15px"}}>View Details</button></span>
          }
        </div> :
          <div className="bookingInfo">
            <h3>Booking Details</h3>
            {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
            : null}
              {loading ? <Loader name="Updating Event..." /> : null}
            <p><strong>User ID:</strong> {props.booking.user}</p>
            <p><strong>User Name:</strong>{userLookup()}</p>
            <p><strong>Link:</strong> {props.booking.link}</p>
            <p><strong>Start Time:</strong><Moment format="hh:mm a">{props.booking.slotStartTime}</Moment></p>
            <div>
              <button className="bookingButtons" onClick={() => setShowDetails(false)}>Hide Details</button>
              <button className="bookingButtons" onClick={confirmClear}>Clear Booking</button>
            </div>
          </div>
      }
    </div>
  )
}

export default BookingCard;
