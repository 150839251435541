import React, {useState, useRef, useEffect} from 'react';
import Select from 'react-select'
import {useStore} from '../../store.js';
import postRequest from '../../functions/postRequest';
import DateTime from '../helperComponents/DateTime';
import ImageDisplay from '../helperComponents/ImageDisplay';
import getRequest from '../../functions/getRequest';

const CreateAAEvent = props => {
  const selectInputRef = useRef();
// App State Variable
  const user = useStore(state => state.user);
  const bands = useStore(state => state.bands);
  const setAAEventsFetched = useStore(state => state.setAAEventsFetched);
// Input Variables
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [duration, setDuration] = useState('');
  const [price, setPrice] = useState('');
  const [photo, setPhoto] = useState(0);
  const [bandSelected, selectBand] = useState(-1);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('18:00');
  const [time2, setTime2] = useState('19:00');
  const [gallery, setGallery] = useState(0)
//  Message variables
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('messageSuccess');
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const images = useStore(state => state.images);
  const imagesFetched = useStore(state => state.imagesFetched);
  const setImagesFetched = useStore(state => state.setImagesFetched);
  const setImages = useStore(state => state.setImages);
  useEffect(() => {
    console.log(imagesFetched);
    if (!imagesFetched) {
      const getImages = async () => {
        getRequest("image", {}, {})
          .then(res => {
            let newArr = res.data.data;
            console.log(res.data.data);
            setImagesFetched(true);
            setImages(newArr);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getImages();
    }
  },[imagesFetched, setImagesFetched, setImages]);
  const resetInputs = () => {
    setTime('18:00');
    setTime2('19:00');
    setDate(new Date())
    setName('');
    setDesc('');
    setDuration('');
    setPrice('');
  }
  const options1 = [
    {value:'2', label:'2 minutes'},
    {value:'5', label:'5 minutes'},
    {value:'10', label:'10 minutes'},
  ]
  const options2 = [
    {value:'free', label:"Free"},
    {value:'5', label:'5 dollars'},
    {value:'10', label:'10 dollars'},
    {value:'15', label:'15 dollars'},
    {value:'20', label:'20 dollars'},
    {value:'25', label:'25 dollars'},
    {value:'35', label:'35 dollars'},
    {value:'50', label:'50 dollars'}
  ]
  const clearBand = () => {
    selectBand(-2);
    selectInputRef.current.select.setValue(-2);
  }
  const handleSubmit = async () => {
    if (name.length < 5 || desc.length < 10) {
      messageTimer('Event name must be at least 5 character and/or description must be at least 10.', 'messageError')
      return;
    }
    if (bandSelected < 0) {
      messageTimer('All Access events must have an artist or band associated.', 'messageError');
      return;
    }
    const start = new Date(date.toString().substring(0,16) + time);
    const end = new Date(date.toString().substring(0,16) + time2);
    if (duration === '' || price === '') {
      messageTimer('Please set the meeting duration and price per session.', 'messageError');
      return;
    }
    await postRequest('allaccess', {
      band:bands[bandSelected].id,
      photo: photo ? bands[bandSelected].groupPhoto : bands[bandSelected].titlePhoto,
      startTime:start,
      endTime:end,
      date:date,
      title:name,
      description:desc,
      meetingDuration:duration,
      paid: price === 'free' ? false : true,
      price: price,
      location:'online',
      type:'allAccess',
      venue:'PunkHubLive'
    }, {Authorization: `Bearer ${user.token}`})
      .then(async res => {
        messageTimer('Event Created!!!', 'messageSuccess');
        resetInputs();
        setAAEventsFetched(false);
      })
      .catch(err => {
        messageTimer('Event not created, Reason: ' + err.response.data.error, 'messageError');
      })
  }
  const setPhotoFunc = () => {
    let num = photo + 1
    if (photo === 3) {
      setPhoto(0)
    } else {
      setPhoto(num)
    }
  }
  const setPrevGallery = () => {
    if (images.length > 0) {
      let index = gallery;
      index--;
      if (index < 0) {
        index = images.length - 1
        setGallery(index);
      }else {
        setGallery(index);
      }
    }else {
      return
    }
  }
  const setNextGallery = () => {
    if (images.length > 0) {
      let index = gallery;
      index++
      if (index >= images.length) {
        setGallery(0);
      }else {
        setGallery(index);
      }
    }else {
      return
    }
  }
  return(
    <div>
      {message.length > 0 ?
        <div className={messageType}><p className='message'>{message}</p></div>
      : null}
      <input className='eventInput' placeholder='Event Name' value={name} onChange={e => setName(e.target.value)}/>
      <textarea className='eventInput eventDesc' placeholder='Event Description' value={desc} onChange={e => setDesc(e.target.value)} />
      <Select className='select' ref={selectInputRef} options={props.bandList} value={props.bandList[bandSelected]} placeholder='Select The Band' onChange={selection => selectBand(selection.value)} />
      <button onClick={clearBand}>Remove Band</button>
      {bandSelected >= 0 ?
        <div className='photoDiv'>
          <button className="toggleButtonCard" onClick={setPhotoFunc}>Toggle Photo</button>
          {photo === 0 ?
            <ImageDisplay src={bands[bandSelected].groupPhoto} text={'Group Photo Selected'} /> : photo === 1 ?
            <ImageDisplay src={bands[bandSelected].titlePhoto} text={'Title Photo Selected'} /> :
            <div>{images.length > 0 && images.length > 0 ?
                <div>
                  <h2>Image Gallery Selected</h2>
                  <h4>{images[gallery].name}</h4>
                  <div className="galleryDiv">
                    <p onClick={setPrevGallery} className="galleryButton">Previous</p>
                    <ImageDisplay src={images[gallery].url} text={""}/>
                    <p onClick={setNextGallery} className="galleryButton">Next</p>
                  </div>
                </div> : <p>The Image Gallery is Empty</p>}

            </div>
          }
        </div> : <p>Please Select the band.</p>
      }
      <Select className='select' options={options1} placeholder='Meeting Duration' onChange={selection => setDuration(selection.value)} />
      <Select className='select' options={options2} placeholder='Meeting Price' onChange={selection => setPrice(selection.value)} />
      <DateTime time={time} time2={time2} setTime={setTime} setTime2={setTime2} setDate={setDate} date={date}/>
      <button className='submitButton' onClick={handleSubmit}>Submit</button>
      {message.length > 0 ?
        <div className={messageType}><p className='message'>{message}</p></div>
      : null}
    </div>
  )
}

export default CreateAAEvent;
