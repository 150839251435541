import React,{useState} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import deleteRequest from '../../functions/deleteRequest'
import {useStore} from '../../store.js';
import EditBandCard from './EditBandCard';

const BandCard = props => {
  const [edit, setEdit] = useState(false);
  const user = useStore(state => state.user);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const setBandsFetched = useStore(state => state.setBandsFetched);
  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete band titled: \n' + props.band.name,
      buttons: [
        {
          label: 'Yes, delete.',
          onClick: () => deleteBand(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const deleteBand =() => {
    deleteRequest(`bands/${props.band._id}`,{},{Authorization: `Bearer ${user.token}`})
      .then(res => {
        console.log(res);
        console.log('success');
        setMessageType('messageSuccess');
        setMessage('Success, band deleted! Please refresh page');
        setBandsFetched(false);
      })
      .catch(err => {
        console.log(err);
        console.log('fail');
        setMessageType('messageError');
        setMessage('Did not update band, sorry!')
      })
  }
  return (
    <div>
      {!edit ?
        <div className='cardDiv'>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <h2>{props.band.name}</h2>
          <p className='cardHeader'>Description:</p>
          <p>{props.band.description}</p>
          <p className='cardHeader'>Group Photo:</p>
          <img className='image' alt='Event' src={props.band.groupPhoto} />
          <p className='cardHeader'>Title Photo:</p>
          <img className='image' alt='Event' src={props.band.titlePhoto} />
          <p className='cardHeader'>Website:</p>
          <p>{props.band.website}</p>
          <p className='cardHeader'>Facebook:</p>
          <p>{props.band.facebook}</p>
          <p className='cardHeader'>Twitter</p>
          <p>{props.band.twitter}</p>
          <p className='cardHeader'>Instagram:</p>
          <p>{props.band.instagram}</p>
          <div className='buttonDiv'>
            <button className="toggleButtonCard" onClick={() => setEdit(true)}>Edit Band</button>
            <button className="deleteButtonCard" onClick={confirmDelete}>Delete Band</button>
          </div>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
        </div> : <EditBandCard band={props.band}/>
      }
    </div>
  )
}

export default BandCard;
