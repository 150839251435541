import React from 'react';

const ImageDisplay= props => {
  return(
    <div>
      <p style={{fontSize:"1.3rem"}}><strong>{props.text}</strong></p>
      <img className='image' alt='default' src={props.src} />
    </div>
  )
}

export default ImageDisplay;
