import React,{useState} from 'react';
import {useStore} from '../../store.js';
import ImageUpload from '../helperComponents/ImageUpload';
import ImageDisplay from '../helperComponents/ImageDisplay';
import postRequest from '../../functions/postRequest';
import validator from 'validator'

const CreateAd = () => {
  const [nickName, setNickName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [link, setLink] = useState("")
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('messageSuccess');
  const user = useStore(state => state.user);
  const setAdsFetched = useStore(state => state.user);
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const handleSubmit = () => {
    if (nickName.length < 5 || nickName.length > 20) {
      messageTimer('The nickname must be between 5 and 20 characters', 'messageError')
    }
    if (companyName.length < 5 || companyName.length > 20) {
      messageTimer('The nickname must be between 5 and 20 characters', 'messageError')
    }
    if (!validator.isURL(link)) {
      messageTimer('Please Enter a Valid URL', "messageError")
      return
    }
    if (file === null) {
      messageTimer('You must add an image for the ad.', 'messageError')
      return;
    }
    postRequest("ads/",{
      companyName:companyName,
      nickName: nickName,
      linkUrl:link,
      image: file.base64.split(',')[1]
    }, {Authorization: `Bearer ${user.token}`})
    .then(async res => {
      console.log("success?")
      setMessage('Ad schema Created!!!', 'messageSuccess');
      resetInputs();
      setAdsFetched(false);
    })
    .catch(err => {
      messageTimer('Ad not created, Reason: \n' + err.response.data.error, 'messageError');
      console.log(err.response)
    })
  }
  const resetInputs = () => {
    setNickName("");
    setCompanyName("");
    setLink("");
    setFile(null)
  }
  console.log(nickName)
  return (
    <div>
      <h2>Create A New Ad Schema</h2>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
      <input className='eventInput' placeholder='Ad Nickname' value={nickName} onChange={e => setNickName(e.target.value)}/>
      <p className="instructionText">The Ad nickname should contain no spaces and capitalize each word, eg. MayberryAd or TenacityAd</p>
      <input className='eventInput' placeholder='Ad Company Name' value={companyName} onChange={e => setCompanyName(e.target.value)}/>
      <p className="instructionText">The Ad company name is the name to be displayed above the website after the ad is clicked</p>
      <input className='eventInput' type="url" placeholder='Ad Website Link' value={link} onChange={e => setLink(e.target.value)}/>
      <p className="instructionText">Link to the companies website. Make sure the link starts with https://</p>
      <ImageUpload setFile={setFile} text={'Upload The Ad Banner Image'} />
      <button onClick={() => setFile(null)}>Remove</button>
      {file !== null ?
        <ImageDisplay src={file.base64} text={'Ad Image Selected'} /> : null
      }
      <p style={{width:"75%",margin:"auto", marginBottom:"50px"}}>The Ad Image should be a .jpg image 980x120, or a similar aspect ratio. As in height to width about 8:1.</p>
      <button className='submitButton' onClick={handleSubmit}>Submit</button>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
    </div>
  )
}

export default CreateAd;
