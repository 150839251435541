import React, {useState} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import deleteRequest from '../../functions/deleteRequest'
import getRequest from '../../functions/getRequest'
import EditUserCard from './EditUserCard';
import {useStore} from '../../store.js';



const UserCard = props => {
  const [toggleEdit, setEdit] = useState(false);
  const [message, setMessage] = useState('');
  const bands = useStore(state => state.bands);
  const setUsersFetched = useStore(state => state.setUsersFetched);
  const [messageType, setMessageType] = useState('');
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type);
  }
  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete user: \n' + props.user.name,
      buttons: [
        {
          label: 'Yes, delete.',
          onClick: () => deleteUser(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const deleteUser = () => {
    deleteRequest(`users/${props.user._id}`,{},{Authorization: `Bearer ${localStorage.getItem("toke")}`})
      .then(res => {
        messageTimer("Successfully deleted the event.", "messageSuccess");
        setUsersFetched(false);
      })
      .catch(err => {
        messageTimer("User was not deleted.", "messageError");
      })

  }
  const sendReset = () => {
    getRequest(`users/forgotPassAdmin/${props.user.id}`,{}, {Authorization: `Bearer ${localStorage.getItem("toke")}`})
      .then(res => {
        messageTimer("Reset link was sent", "messageSuccess")
      })
      .catch(err => {
        messageTimer("Reset Link was not able to be sent:" + err.message, "messageError")
      })
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getFb = fb => {
    let b = bands.find(x => x._id === fb);
    console.log(b)
      // return b.name;
  }
  return (
    <div>
      {toggleEdit ? <EditUserCard user={props.user} /> :
        <div className='cardDiv'>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}

          <h2>{props.user.name}</h2>
          <button onClick={sendReset} className="toggleButtonCard">Send Reset Password Link</button>
          <div className="userGridBox">
          <div className="userDiv"><p className='cardHeader'>Username:</p>
          <p>{props.user.username}</p></div>
            <div className="userDiv"><p className='cardHeader'>Role:</p>
            <p>{capitalizeFirstLetter(props.user.role)}</p></div>
            <div className="userDiv"><p className='cardHeader'>Email:</p>
            <p>{props.user.email}</p></div>
            <div className="userDiv"><p className='cardHeader'>Phone Number:</p>
            <p>{props.user.phoneNumber}</p></div>
              <div className="userDiv"><p className='cardHeader'>Favorite Bands:</p>
                {props.user.favoriteBands.length > 0 ?
                  <div>
                    {props.user.favoriteBands.map((fb,i) => <p key={i}>{getFb(fb)}</p>)}
                  </div> : <p>No Favorite Bands yet.</p>
                }</div>
            <div className="userDiv"><p className='cardHeader'>Email Verified?</p>
              <p>{capitalizeFirstLetter(props.user.verified.toString())}</p></div>
            </div>
          <div className='buttonDiv'>
            <button className="toggleButtonCard" onClick={() => setEdit(true)}>Edit User</button>
            <button className="deleteButtonCard" onClick={confirmDelete}>Delete User</button>
          </div>
        </div>
      }
    </div>
  )
}

export default UserCard;
