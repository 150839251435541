import React from 'react';
import { DatePicker } from 'react-rainbow-components';
import TimePicker from 'react-time-picker';


const DateTime = props => {
  return(
    <div>
      <div className='dateDiv'>
        <span className='spanHeader'>Event Date</span>
        <DatePicker className="datepicker" value={props.date} onChange={date => props.setDate(date)} />
      </div>
      <div className='outerTimeDiv'>
        <div className='innerTimeDiv'>
          <span className='spanHeader'>Start Time</span>
          <TimePicker
          onChange={props.setTime}
          value={props.time}
          className='timePicker'
          label='Start'
          />
        </div>
        <div className='innerTimeDiv'>
          <span className='spanHeader'>End Time</span>
          <TimePicker
          onChange={props.setTime2}
          value={props.time2}
          className='timePicker'
          label='End'
          />
        </div>
      </div>
    </div>
  )
}

export default DateTime;
