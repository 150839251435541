import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';
import {useStore} from '../store.js';

const NavBar = () => {
  const user = useStore(state => state.user);
  const setUser = useStore(state => state.setUser);
  const logOut = () => {
    const emptyUser = {
      name: '',
      email:'',
      authed: false,
      role:'',
      token:'',
      band:''
    };
    setUser(emptyUser);
    localStorage.removeItem("toke");
  }
  return (
    <div className='navBar'>
      <div className='navSection'><span className='navText'>PunkHubLive</span></div>
      {user.authed ?
        <div className='navSection'><Link to='/'><span className='navText'>Dashboard</span></Link></div> :
        <div className='navSection'></div>
      }
      {user.authed ?
        <div className='navSection'><span className='navText'>{user.name}</span></div> :
        <div className='navSection'></div>
      }
      {user.authed ?
        <div className='navSection'><span onClick={logOut} className='navText navLogOut'>Log Out</span></div> :
        <div className='navSection'><span className='navText'>Not Logged In</span></div>
      }
    </div>
  )
}

export default NavBar;
