import React,{useState, useEffect} from 'react';
import '../App.css';
import {Link, Redirect} from 'react-router-dom'
import {useStore} from '../store.js';
import CreateAd from "./adComponents/CreateAd"
import ViewAds from "./adComponents/ViewAds"
import getRequest from "../functions/getRequest"

const Adverts = () => {
  const [index, setIndex] = useState(1);
  const user = useStore(state => state.user);
  const adsFetched = useStore(state => state.adsFetched);
  const setAdsFetched = useStore(state => state.setAdsFetched);
  const setAds = useStore(state => state.setAds);
  useEffect(() => {
    console.log(adsFetched);
    if (!adsFetched) {
      const getAds = async () => {
        getRequest("ads", {}, {})
          .then(res => {
            setAdsFetched(true);
            setAds(res.data.data);
          })
          .catch(err => {
            console.log(err.message);
          })
      }
      getAds();
    }
  },[adsFetched, setAdsFetched, setAds]);
  return(
    <div>
      {user.authed ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      {user.role === 'admin' || user.role === 'band' ? null :
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      }
      <h1>Advertising!</h1>
      <div>
        <Link to='/'>Back To Dashboard</Link>
          <div className='flexRow'>
            <p onClick={() => setIndex(1)} className='eventTopLink'>Create A New Ad</p>
            <p onClick={() => setIndex(2)} className='eventTopLink'>View And Edit Ads</p>
          </div>
          {index === 0 ? <p>Click an action above to work on an event.</p> :
          index === 1 ? <CreateAd /> :
          <ViewAds />
          }
      </div>
    </div>
  )
}

export default Adverts
