import React, {useState} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import deleteRequest from '../../functions/deleteRequest'
import {useStore} from '../../store.js';

const ImageCard = props => {
  const user = useStore(state => state.user);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type)
  }
  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete ad titled: \n' + props.image.name,
      buttons: [
        {
          label: 'Yes, delete.',
          onClick: () => deleteAd(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const deleteAd = () => {
    deleteRequest(`image/${props.image._id}`,{},{Authorization: `Bearer ${user.token}`})
      .then(res => {
        messageTimer("Successfully deleted the event.", "messageSuccess");
        console.log("success")
      })
      .catch(err => {
        messageTimer("Event was not deleted.", "messageError");
        console.log("err")
      })

  }
  return (
    <div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
        <div className="cardDiv">
          <p className="eventTitle">{props.image.name}</p>
          <img className='image' alt='Event' src={props.image.url} />
          <p><strong>Image Storage Path: </strong>{props.image.url}</p>
          <div className='buttonDiv'>
            <button className="deleteButtonCard" onClick={confirmDelete}>Delete Image</button>
          </div>
        </div>
      {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div> : null}
    </div>
  )
}

export default ImageCard;
