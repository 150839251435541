import React,{useState} from 'react';
import UserCard from './UserCard';
// import putRequest from "../../functions/putRequest";
// import {useStore} from '../../store.js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-select'
import putRequest from "../../functions/putRequest";
import {useStore} from '../../store.js';



const EditUserCard = props => {
  // const user = useStore(state => state.user);
  const [toggleEdit, setEdit] = useState(true);
  const [name, setName] = useState(props.user.name);
  const [email, setEmail] = useState(props.user.email);
  const [username, setUsername] = useState(props.user.username);
  const [phone, setPhone] = useState(props.user.phoneNumber);
  const [verified, setVerified] = useState(props.user.verified);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const setUsersFetched = useStore(state => state.setUsersFetched);
  const messageTimer = (message, type) => {
    setTimeout(() => {
      setMessage('');
    }, 5000);
    setMessage(message);
    setMessageType(type);
  }
  const options = [
    {value:false, label:'False'},
    {value:true, label:'True'}
  ];
  const confirmSubmit = () => {
    confirmAlert({
      title: 'Confirm to update',
      message: 'Are you sure you want to update user named: \n' + props.user.name,
      buttons: [
        {
          label: 'Yes, update.',
          onClick: () => updateUser(),
        },
        {
          label: 'No, nevermind.',
          onClick: () => console.log('cancelled'),
        }
      ]
    });
  }
  const updateUser = () => {
    let info;
    if (name.length < 4) {
      messageTimer("The event name must be at least 4 characters long.", "messageError");
      return;
    }
    let re = /\S+@\S+\.\S+/;
    if (email.length === 0) {
      messageTimer("You must add an email.", 'messageError');
      return;
    }
    if(!re.test(email)) {
      messageTimer("Email must be in email format.", 'messageError');
      return;
    }
    info = {
      name:name,
      username:username,
      email: email,
      verified: verified,
      phoneNumber: phone,
    }
    update(info);
  }
  const update = info => {
    putRequest(`users/${props.user._id}`,info, {Authorization: `Bearer ${localStorage.getItem('toke')}`})
      .then(res => {
        console.log(res);
        console.log('success');
        messageTimer("User updated!!", 'messageSuccess');
        setUsersFetched(false);

      })
      .catch(err => {
        console.log(err);
        console.log('fail');
        messageTimer('Request failed:' + err.response.data.error, "messageError");
      })
  }
  return(
    <div>
      {!toggleEdit ? <UserCard user={props.user} /> :
        <div className='cardDiv'>
          {message.length > 0 ? <div className={messageType}><p className='message'>{message}</p></div>
          : null}
          <p className='formSectHeader'><strong>User Name:</strong></p>
          <input className="editInput" value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
          <p className='formSectHeader'><strong>User Name:</strong></p>
          <input className="editInput" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" />
          <p className='formSectHeader'><strong>Email:</strong></p>
          <input className='editInput' value={email} onChange={e => setEmail(e.target.value)} />
          <p className='formSectHeader'><strong>Phone Number:</strong></p>
          <input type='tel' className='editInput' placeholder='Phone Number' value={phone} onChange={e => setPhone(e.target.value)} />
          <p className='formSectHeader'><strong>Email Verified?:</strong></p>
          <Select className='select' options={options} placeholder='Email Verified' onChange={selection => setVerified(selection.value)} />
          <div className='buttonDiv'>
            <button className="submitButtonCard" onClick={confirmSubmit}>Submit User</button>
            <button className="toggleButtonCard" onClick={() => setEdit(false)}>Back To View</button>
          </div>
        </div>
      }
    </div>
  )
}

export default EditUserCard;
